import CloseIcon from '@assets/icons/CloseIcon';
import useAuth from '@hooks/useAuth';
import useBreakpoint from '@hooks/useBreakpoint';
import {
  BillingCardInfo,
  billingInitialValues,
  billingValidationSchema,
  cardElementOptions,
} from '@pages/checkout';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import axiosInstance from '@utils/axios-service';
import clsx from 'clsx';
import { useFormik } from 'formik';
import {
  Alert,
  Button,
  Col,
  FormControl,
  FormGroup,
  FormSelect,
  FormText,
  Modal,
  ModalTitle,
  Row,
  Spinner,
} from 'react-bootstrap';

type UpdateBillingModalProps = {
  interval?: 'month' | 'year';
  open: boolean;
  onClose: (refresh?: boolean) => void;
};
const UpdateBillingModal: React.FC<UpdateBillingModalProps> = ({
  interval,
  open,
  onClose,
}) => {
  const { isMobile } = useBreakpoint();
  const { refetchUser } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const formik = useFormik<BillingCardInfo>({
    initialValues: billingInitialValues,
    validationSchema: billingValidationSchema,
    onSubmit: async (
      { name, country, postal_code },
      { setStatus, resetForm },
    ) => {
      setStatus('');
      if (!stripe || !elements) {
        setStatus('Strip elements is required');
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);
      if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
        setStatus('Strip elements is required');
        return;
      }

      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name,
            address: {
              country,
              postal_code,
            },
          },
        });
        if (error) {
          setStatus('Could not create payment method');
          return;
        }
        await axiosInstance.put(`/api/profile/account/update`, {
          payment_method_id: paymentMethod.id,
          payment_plan: interval === 'month' ? 'monthly' : 'yearly',
        });
        onClose(true);
        resetForm();
        await refetchUser();
      } catch (err: any) {
        setStatus(
          err.response?.data?.message || 'Could not subscribe payment.',
        );
      }
    },
  });
  return (
    <Modal show={open} onHide={() => onClose()} size="lg">
      <Modal.Header className="px-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          Update Billing Information
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="p-4 p-md-4 pt-0 d-flex gap-4 flex-column">
        {formik.status && <Alert variant="danger">{formik.status}</Alert>}

        <FormGroup>
          <FormControl
            placeholder="Cardholder name"
            size={isMobile ? undefined : 'lg'}
            {...formik.getFieldProps('name')}
            isInvalid={formik.touched.name && !!formik.errors.name}
          />
          {formik.touched.name && !!formik.errors.name && (
            <FormText className="text-danger">{formik.errors.name}</FormText>
          )}
        </FormGroup>
        <CardNumberElement
          className={clsx(
            'form-control',
            isMobile ? 'py-2' : 'py-35 rounded-3',
          )}
          options={cardElementOptions}
        />

        <Row>
          <Col xs={6} sm={3} className="pe-sm-1 mb-4">
            <CardCvcElement
              className={clsx(
                'form-control',
                isMobile ? 'py-2' : 'py-35 rounded-3',
              )}
              options={cardElementOptions}
            />
          </Col>
          <Col xs={6} sm={3} className="px-sm-1">
            <CardExpiryElement
              className={clsx(
                'form-control',
                isMobile ? 'py-2' : 'py-35 rounded-3',
              )}
              options={cardElementOptions}
            />
          </Col>
          <Col xs={6} sm={3} className="px-sm-1">
            <FormGroup>
              <FormSelect
                {...formik.getFieldProps('country')}
                isInvalid={formik.touched.country && !!formik.errors.country}
                size={isMobile ? undefined : 'lg'}
              >
                <option value={'US'}>US</option>
                <option value={'CA'}>CA</option>
              </FormSelect>
              {formik.touched.country && !!formik.errors.country && (
                <FormText className="text-danger">
                  {formik.errors.country}
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col xs={6} sm={3} className="ps-sm-1">
            <FormGroup>
              <FormControl
                placeholder="ZIP"
                {...formik.getFieldProps('postal_code')}
                isInvalid={
                  formik.touched.postal_code && !!formik.errors.postal_code
                }
                size={isMobile ? undefined : 'lg'}
              />
              {formik.touched.postal_code && !!formik.errors.postal_code && (
                <FormText className="text-danger">
                  {formik.errors.postal_code}
                </FormText>
              )}
            </FormGroup>
          </Col>
        </Row>
        <p className="mb-4 fw-semibold">
          Please note: <br />
          Once you update your billing information, you'll be immediately
          charged for any past due payments. Changing your billing location
          could impact your applicable tax rate. If you are changing your
          billing country, any VAT/tax ID you use should be consistent with that
          country.
        </p>
        <Button
          className="w-100"
          size={isMobile ? undefined : 'lg'}
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting && <Spinner size="sm" className="me-3" />}
          Update Billing Information
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateBillingModal;
