import { ChartData, ChartOptions } from 'chart.js';
import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export interface ScanHistoryItem {
  from_google: number;
  from_source: number;
  month: string;
}

export interface ScanHistoryData {
  period?: string;
  chart?: ScanHistoryItem[];
}

const ScanHistoryCard: React.FC<ScanHistoryData> = ({ period, chart = [] }) => {
  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          boxHeight: 9,
          boxWidth: 9,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          includeBounds: true,
          callback: (v, index) => {
            const tickValue = parseInt(`${v}`);
            return tickValue >= 1000
              ? Math.round(tickValue / 100) / 10 + 'k'
              : tickValue;
          },
        },
      },
    },
  };

  const formattedData: ChartData<'bar'> = useMemo(() => {
    return {
      labels: chart.map((item) => item.month),
      datasets: [
        {
          fill: true,
          label: 'Delisted from Google',
          data: chart.map((item) => item.from_google),
          backgroundColor: '#f96e85',
          barThickness: 17,
        },
        {
          fill: true,
          label: 'Removed from Source',
          data: chart.map((item) => item.from_source),
          backgroundColor: '#6aaaf9',
          barThickness: 17,
          borderRadius: 5,
        },
      ],
    };
  }, [chart]);
  return (
    <Card className="shadow-sm border-slight rounded-3 p-4">
      <div className="bg-white d-flex justify-content-between ">
        <div className="d-flex gap-2 align-items-center">
          <p className="fs-lg fw-semibold">Scan history</p>
          <span className="fs-xs">Past {period}</span>
        </div>
      </div>
      <div>
        <Bar options={options} data={formattedData} height={'240px'} />
      </div>
    </Card>
  );
};
export default ScanHistoryCard;
