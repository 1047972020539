import React, { useEffect } from 'react';
import {
  Alert,
  Button,
  Col,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  FormText,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import UserJson from 'types/user.type';
import axiosInstance from '@utils/axios-service';
import useAuth from '@hooks/useAuth';
import UserAvatar from '@components/UserAvatar';

type FormikData = UserJson.Profile & {
  file?: File;
  autoFetch: boolean;
  picture?: string;
};

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const {
    authUser,
    paymentDetails,
    user,
    profiles,
    refetchUser,
    refetchProfiles,
  } = useAuth();
  const name = user?.name || authUser?.displayName || '';
  const initialValues: FormikData = {
    id: 0,
    name,
    page_url: '',
    instagram_username: '',
    twitter_username: '',
    aliases: '',
    category: '',
    custom_search_keywords: '',
    file: undefined,
    autoFetch: false,
    picture: user?.avatar,
  };
  useEffect(() => {
    if (paymentDetails?.payment_method_details) {
      navigate(appRoutes.dashboard);
    } else if (profiles.length > 0) {
      navigate(appRoutes.checkout);
    }
  }, [profiles, paymentDetails]);
  const validationSchema = yup.object({
    name: yup.string().required('Full name is required'),
    category: yup.string().required('Category is required'),
    instagram_username: yup.string(),
    aliases: yup.string(),
  });
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (
      {
        name,
        page_url,
        instagram_username,
        twitter_username,
        aliases,
        category,
        custom_search_keywords,
        file,
        autoFetch,
      },
      { setSubmitting, setStatus },
    ) => {
      setStatus('');
      setSubmitting(true);
      try {
        const accountFormData = new FormData();
        accountFormData.append('name', name || '');
        if (file) {
          accountFormData.append('avatar', file, file.name.slice(-99));
        }
        await axiosInstance.put('/api/profile/account/update', accountFormData);
        const profileFormData = new FormData();
        profileFormData.append('name', name || '');
        profileFormData.append('page_url', page_url || '');
        profileFormData.append('aliases', aliases || '');
        profileFormData.append('category', category || '');
        profileFormData.append('instagram_username', instagram_username || '');
        profileFormData.append('twitter_username', twitter_username || '');
        profileFormData.append(
          'custom_search_keywords',
          custom_search_keywords || '',
        );
        profileFormData.append(
          'auto_fetch_image_from_onlyfans',
          autoFetch ? 'true' : 'false',
        );
        if (file) {
          profileFormData.append('picture', file, file.name.slice(-99));
        }
        await axiosInstance.post('/api/profile/add', profileFormData);
        await refetchUser();
        await refetchProfiles();
        navigate(appRoutes.checkout);
      } catch (err: any) {
        setStatus(err.response?.data?.message || 'Failed to create account');
      }
      setSubmitting(false);
    },
  });
  return (
    <>
      <h2 className="fs-xl">Perfect! Now let’s setup your bot</h2>

      <div className="d-flex align-items-center">
        <div className="me-4">
          <UserAvatar
            size={85}
            editable
            imgUrl={formik.values.picture}
            onChangeFile={(file) => {
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  formik.setFieldValue('file', file);
                  formik.setFieldValue('picture', reader.result as string);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
        </div>

        <div>
          <FormCheck
            id={`auto-fetch`}
            {...formik.getFieldProps('autoFetch')}
            label="Auto fetch from OnlyFans"
          />
        </div>
      </div>
      <Row>
        <Col xs={6} sm={6} md={6}>
          <FormGroup className="mb-4">
            <FormLabel>Full Name</FormLabel>
            <FormControl
              placeholder={'Full Name'}
              {...formik.getFieldProps('name')}
              isInvalid={formik.touched.name && !!formik.errors.name}
            />
            {formik.touched.name && !!formik.errors.name && (
              <FormText className="text-danger">{formik.errors.name}</FormText>
            )}
          </FormGroup>
        </Col>
        <Col xs={6} sm={6} md={6}>
          <FormGroup className="mb-4">
            <FormLabel>Category</FormLabel>
            <FormSelect
              {...formik.getFieldProps('category')}
              isInvalid={formik.touched.category && !!formik.errors.category}
            >
              <option value="" disabled></option>
              <option value="Creator">Creator</option>
              <option value="Celebrity">Celebrity</option>
            </FormSelect>
            {formik.touched.category && !!formik.errors.category && (
              <FormText className="text-danger">
                {formik.errors.category}
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-4">
            <FormLabel>Instagram @</FormLabel>
            <FormControl
              placeholder="@username"
              {...formik.getFieldProps('instagram_username')}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-4">
            <FormLabel>Twitter @</FormLabel>
            <FormControl
              placeholder="@username"
              {...formik.getFieldProps('twitter_username')}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup className="mb-4">
            <FormLabel>Exclusive Content URL</FormLabel>
            <FormControl
              placeholder="Exclusive Content URL"
              {...formik.getFieldProps('page_url')}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup className="mb-4">
            <FormLabel>Alias Names (optional)</FormLabel>
            <FormControl
              as="textarea"
              placeholder="Also known as..."
              rows={2}
              {...formik.getFieldProps('aliases')}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup className="mb-4">
            <FormLabel>Keywords (optional)</FormLabel>
            <FormControl
              as="textarea"
              placeholder="Keywords"
              rows={2}
              {...formik.getFieldProps('custom_search_keywords')}
            />
          </FormGroup>
        </Col>
      </Row>
      {formik.status && <Alert variant="warning">{formik.status}</Alert>}
      <div className="d-flex justify-content-between">
        <Button
          variant="secondary"
          disabled={formik.isSubmitting}
          onClick={() => navigate(appRoutes.selectUserType)}
        >
          Back
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
          Continue
        </Button>
      </div>
    </>
  );
};

export default UserInfo;
