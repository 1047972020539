import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  BillingIcon,
  DashboardIcon,
  FaqIcon,
  HamburgIcon,
  ReportsIcon,
  SettingsIcon,
} from '@assets/icons';
import AppLogo from '@components/AppLogo';
import appRoutes from '@constants/routes.app';
import appConfig from '@constants/appConfig.app';
import useBreakpoint from '@hooks/useBreakpoint';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import UpdateAccountInfoModal from '@components/modals/UpdateAccountInfoModal';
import useAuth from '@hooks/useAuth';
import FlowerCheckIcon from '@assets/icons/FlowerCheckIcon';

export type Menu = {
  icon: React.ReactNode;
  title: string;
  link: string;
};

const menus: Menu[] = [
  {
    icon: <DashboardIcon />,
    title: 'Dashboard',
    link: appRoutes.dashboard,
  },
  {
    icon: <ReportsIcon />,
    title: 'Reports',
    link: appRoutes.reports,
  },
  {
    icon: <FlowerCheckIcon />,
    title: 'Whitelisted Domains',
    link: appRoutes.whitelistedDomains,
  },
  {
    icon: <BillingIcon />,
    title: 'Billing',
    link: appRoutes.billing,
  },
  {
    icon: <SettingsIcon />,
    title: 'Accounts',
    link: appRoutes.accounts,
  },
  {
    icon: <FaqIcon />,
    title: 'FAQ',
    link: appRoutes.faq,
  },
];

type DashboardAsideBarProps = {
  expanded: boolean;
  setExpanded: (v: boolean) => void;
};
const DashboardAsideBar: React.FC<DashboardAsideBarProps> = ({
  expanded,
  setExpanded,
}) => {
  const [open, setOpen] = useState(false);
  const {
    refetchProfiles: refetchGlobalProfiles,
    refetchPaymentDetails,
    refetchUser,
  } = useAuth();
  const { isMobile } = useBreakpoint();
  const [changing, setChanging] = useState<'started' | ''>('');
  function handleToggle() {
    setExpanded(!expanded);
    setChanging('started');
    setTimeout(() => {
      setChanging('');
    }, 300);
  }
  if (!expanded && isMobile) return null;
  return (
    <aside
      className={clsx('dashboard-aside-bar', expanded && 'expanded', changing)}
    >
      <div>
        <div className="d-flex px-4 py-4 align-items-center gap-3 aside-bar-logo">
          <div className="cursor-pointer" onClick={() => handleToggle()}>
            <HamburgIcon />
          </div>
          {expanded && <AppLogo />}
        </div>
        <div className="d-flex flex-column dynamic-element">
          {menus.map((menu, id) => (
            <NavLink
              key={id}
              to={menu.link}
              className={({ isActive }) =>
                clsx(
                  'd-flex px-4 py-3 align-items-center gap-3',
                  isActive && 'bg-active',
                )
              }
            >
              <div
                className="d-flex justify-content-center"
                style={{ minWidth: 24 }}
              >
                {menu.icon}
              </div>
              {expanded && <span className="text-nowrap">{menu.title}</span>}
            </NavLink>
          ))}
          <Button className="mx-3 mt-4" onClick={() => setOpen(true)}>
            {expanded ? 'Add Account' : '+'}
          </Button>
        </div>
      </div>
      {expanded && (
        <div className="p-4 d-flex flex-column dynamic-element mt-auto position-fixed bottom-0">
          <p className="mb-2">{appConfig.APP_NAME}</p>
          <p className="text-muted fs-sm">
            Copyright © {new Date().getFullYear()}
            <br />
            All Rights Reserved
          </p>
        </div>
      )}

      <UpdateAccountInfoModal
        open={open}
        onClose={(refresh) => {
          if (refresh) {
            refetchGlobalProfiles();
            refetchPaymentDetails();
            refetchUser();
          }
          setOpen(false);
        }}
      />
    </aside>
  );
};

export default DashboardAsideBar;
