import AppTypes from '@models/app.type';

const PasswordIcon: React.FC<AppTypes.Icon> = ({
  width = 22,
  height = 22,
  color = 'black',
  className = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 48 48"
      className={className}
    >
      <path d="M39,18H35V13A11,11,0,0,0,24,2H22A11,11,0,0,0,11,13v5H7a2,2,0,0,0-2,2V44a2,2,0,0,0,2,2H39a2,2,0,0,0,2-2V20A2,2,0,0,0,39,18ZM15,13a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7v5H15ZM37,42H9V22H37Z"></path>
      <circle cx="15" cy="32" r="3"></circle>
      <circle cx="23" cy="32" r="3"></circle>
      <circle cx="31" cy="32" r="3"></circle>
    </svg>
  );
};

export default PasswordIcon;
