import axiosInstance from '@utils/axios-service';
import { formatCurrency } from '@utils/string.util';
import { useEffect, useState } from 'react';
import { Alert, Button, Spinner, Table } from 'react-bootstrap';
import UserJson from 'types/user.type';

const BillingHistory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<UserJson.Invoice[]>([]);
  async function loadAllInvoices() {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get('/api/payment/invoices/list');
      setInvoices(data.data.invoices || []);
    } catch (err) {}
    setLoading(false);
  }
  useEffect(() => {
    loadAllInvoices();
  }, []);
  async function downloadInvoice(invoice: UserJson.Invoice) {
    try {
      const { data } = await axiosInstance.get(
        `/api/payment/invoice/download`,
        {
          params: {
            id: invoice.id,
          },
          responseType: 'blob',
        },
      );
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Create a link and click it programmatically
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Invoice-${invoice.date}.pdf`); // or any other filename
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {}
  }
  return (
    <div>
      <h1 className="fs-xxl mb-2 mb-md-4">Billing Receipts</h1>
      {loading && (
        <div className="p-5 text-center">
          <Spinner />
        </div>
      )}
      {!loading && invoices.length === 0 && (
        <Alert variant="danger">You don't have any invoices yet.</Alert>
      )}
      {invoices.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Currency</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, id) => (
              <tr key={id}>
                <td>{invoice.date}</td>
                <td>{formatCurrency(invoice.amount)}</td>
                <td>{invoice.currency}</td>
                <td>
                  <Button onClick={() => downloadInvoice(invoice)}>
                    Download
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default BillingHistory;
