import axios from 'axios';
import { getAccessToken } from './local-storage';
import appConfig from '@constants/appConfig.app';
import appRoutes from '@constants/routes.app';

const axiosInstance = axios.create({
  baseURL: appConfig.APP_API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = getAccessToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.location.href = appRoutes.signin;
    }
    return Promise.reject(error);
  },
);
export default axiosInstance;
