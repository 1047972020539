const DashboardIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="22px">
      <path
        fillRule="evenodd"
        fill="rgb(106, 170, 249)"
        d="M19.950,21.482 L12.600,21.482 C12.020,21.482 11.550,21.011 11.550,20.431 L11.550,13.082 C11.550,12.501 12.020,12.031 12.600,12.031 L19.950,12.031 C20.530,12.031 21.000,12.501 21.000,13.082 L21.000,20.431 C21.000,21.011 20.530,21.482 19.950,21.482 ZM18.900,14.131 L13.650,14.131 L13.650,19.381 L18.900,19.381 L18.900,14.131 ZM19.950,9.931 L12.600,9.931 C12.020,9.931 11.550,9.462 11.550,8.882 L11.550,1.531 C11.550,0.951 12.020,0.481 12.600,0.481 L19.950,0.481 C20.530,0.481 21.000,0.951 21.000,1.531 L21.000,8.882 C21.000,9.462 20.530,9.931 19.950,9.931 ZM18.900,2.581 L13.650,2.581 L13.650,7.832 L18.900,7.832 L18.900,2.581 ZM8.400,21.482 L1.050,21.482 C0.470,21.482 -0.000,21.011 -0.000,20.431 L-0.000,13.082 C-0.000,12.501 0.470,12.031 1.050,12.031 L8.400,12.031 C8.980,12.031 9.450,12.501 9.450,13.082 L9.450,20.431 C9.450,21.011 8.980,21.482 8.400,21.482 ZM7.350,14.131 L2.100,14.131 L2.100,19.381 L7.350,19.381 L7.350,14.131 ZM8.400,9.931 L1.050,9.931 C0.470,9.931 -0.000,9.462 -0.000,8.882 L-0.000,1.531 C-0.000,0.951 0.470,0.481 1.050,0.481 L8.400,0.481 C8.980,0.481 9.450,0.951 9.450,1.531 L9.450,8.882 C9.450,9.462 8.980,9.931 8.400,9.931 ZM7.350,2.581 L2.100,2.581 L2.100,7.832 L7.350,7.832 L7.350,2.581 Z"
      />
    </svg>
  );
};

export default DashboardIcon;
