import CloseIcon from '@assets/icons/CloseIcon';
import { defaultAnnually, defaultMonthly } from '@constants/prices.app';
import useAuth from '@hooks/useAuth';
import moment from 'moment';
import { useState } from 'react';
import {
  Button,
  FormGroup,
  FormLabel,
  Modal,
  ModalTitle,
} from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';

type SwitchCycleConfirmModalProps = {
  isMonthly: boolean;
  open: boolean;
  onConfirm: (confirm?: boolean) => void;
};
const SwitchCycleConfirmModal: React.FC<SwitchCycleConfirmModalProps> = ({
  isMonthly,
  open,
  onConfirm,
}) => {
  const { paymentDetails } = useAuth();
  const [agree, setAgree] = useState(true);
  return (
    <Modal show={open} onHide={() => onConfirm()}>
      <Modal.Header className="px-4 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          Confirm switch to {isMonthly ? 'annually' : 'monthly'} billing?
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={() => onConfirm()}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="p-4 p-md-5 pt-0 pt-md-0 d-flex gap-4 flex-column">
        <p>
          You will be charged ${isMonthly ? defaultAnnually : defaultMonthly}{' '}
          per profile, plus any applicable taxes, per{' '}
          {isMonthly ? 'year' : 'month'} starting on{' '}
          {moment(paymentDetails?.next_payment_date || new Date()).format(
            'DD MMM, yyyy',
          )}
        </p>
        <FormGroup>
          <div className="d-flex gap-3">
            <FormCheckInput
              id="agreeTerm"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            <FormLabel
              className="text-muted fs-sm cursor-pointer"
              htmlFor="agreeTerm"
            >
              I agree that I will be charged
            </FormLabel>
          </div>
        </FormGroup>
        <div className="d-flex justify-content-end gap-3">
          <Button variant="outline-secondary" onClick={() => onConfirm(true)}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm(true)}>
            Switch to {isMonthly ? 'annual' : 'month'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SwitchCycleConfirmModal;
