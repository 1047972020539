import CloseIcon from '@assets/icons/CloseIcon';
import useBreakpoint from '@hooks/useBreakpoint';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Alert,
  Button,
  FormGroup,
  FormLabel,
  FormText,
  Modal,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import PasswordField from '@components/PasswordField';
import authService from '@utils/auth-service';
type ChangePasswordModalProps = {
  open: boolean;
  onClose: () => void;
};

type FormikData = {
  password: string;
  newPassword: string;
  confirmPassword: string;
};

const validationSchema = yup.object({
  password: yup.string().required('Current password is required'),
  newPassword: yup.string().required('New password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword'), ''], 'Password must match'),
});
const initialValues: FormikData = {
  password: '',
  newPassword: '',
  confirmPassword: '',
};
const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  onClose,
}) => {
  const { isMobile } = useBreakpoint();
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (
      { newPassword, password },
      { setStatus, resetForm, setFieldError },
    ) => {
      setStatus('');
      try {
        await authService.changePassword(password, newPassword);
        resetForm();
        onClose();
      } catch (err: any) {
        switch (err.code) {
          case 'auth/invalid-credential':
            setFieldError('password', `Current password is incorrect`);
            return;
          case 'auth/too-many-request':
            setStatus(
              `Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.`,
            );
            return;
          default:
            setStatus(`Failed to update password`);
        }
      }
    },
  });

  return (
    <Modal show={open} onHide={onClose} size="lg">
      <Modal.Header className="px-4 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          Change Password
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="py-4 py-md-5 pt-0 pt-md-0">
        <div className="px-2 px-md-4">
          <FormGroup className="mb-4">
            <FormLabel>Current Password</FormLabel>
            <PasswordField
              size={isMobile ? undefined : 'lg'}
              placeholder="Current Password"
              {...formik.getFieldProps('password')}
              isInvalid={formik.touched.password && !!formik.errors.password}
            />
            {formik.touched.password && formik.errors.password && (
              <FormText className="text-danger">
                {formik.errors.password}
              </FormText>
            )}
          </FormGroup>
          <FormGroup className="mb-4">
            <FormLabel>New Password</FormLabel>
            <PasswordField
              size={isMobile ? undefined : 'lg'}
              placeholder="New Password"
              {...formik.getFieldProps('newPassword')}
              isInvalid={
                formik.touched.newPassword && !!formik.errors.newPassword
              }
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <FormText className="text-danger">
                {formik.errors.newPassword}
              </FormText>
            )}
          </FormGroup>
          <FormGroup className="mb-4">
            <FormLabel>Confirm Password</FormLabel>
            <PasswordField
              size={isMobile ? undefined : 'lg'}
              placeholder="Confirm Password"
              {...formik.getFieldProps('confirmPassword')}
              isInvalid={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
            />
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <FormText className="text-danger">
                  {formik.errors.confirmPassword}
                </FormText>
              )}
          </FormGroup>

          {formik.status && <Alert variant="danger">{formik.status}</Alert>}
          <Button
            className="w-100"
            size={isMobile ? undefined : 'lg'}
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
            Change Password
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ChangePasswordModal;
