import {
  ArcElement,
  Chart,
  ChartData,
  ChartOptions,
  Legend,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip, Legend);
interface DoughnutChartProps {
  data: number[];
}

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  cutout: '55%',
};

const OverviewChart: React.FC<DoughnutChartProps> = ({ data }) => {
  const chartData: ChartData<'doughnut'> = {
    labels: ['Delisted from Google', 'Removed from Source', `Submitted links`],
    datasets: [
      {
        data: data,
        backgroundColor: ['#fc99aa', '#6aaaf9', '#b9f9c8'],
        offset: [0, 0, 40],
      },
    ],
  };
  return <Doughnut data={chartData} options={options} />;
};

export default OverviewChart;
