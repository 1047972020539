const GraphUpDownArrowIcon: React.FC<{ down?: boolean }> = ({ down }) => {
  return (
    <img
      src={`/assets/images/graph-${down ? 'down' : 'up'}-arrow.png`}
      alt=""
    />
  );
};

export default GraphUpDownArrowIcon;
