import appConfig from '@constants/appConfig.app';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="privacy_section">
      {/* <Container fluid={'xxl'}> */}
      <Row>
        <Col lg={12} className="privacy_col py-5">
          <h1 className="py-3 text-center">Privacy Policy</h1>
        </Col>
      </Row>
      {/* </Container> */}
      <Container>
        <Row>
          <Col>
            <h3 className="py-5">
              At {appConfig.APP_NAME}, safeguarding your privacy is paramount.
              This Privacy Policy explains how we collect, utilize, share, and
              protect your personal information concerning our{' '}
              {appConfig.APP_NAME} service.
            </h3>
            <ol>
              <li className="fw-semibold">Information Collection</li>
              <ol>
                <li>
                  <span className="fw-semibold">Personal Data:</span> When
                  registering for {appConfig.APP_NAME}, we collect personal data
                  like your name, email address, and payment details.
                </li>
                <li>
                  <span className="fw-semibold">Usage Details:</span> We gather
                  information on your utilization of {appConfig.APP_NAME},
                  including uploaded content, actions taken, and interactions
                  within the service.
                </li>
              </ol>
              <li className="fw-semibold">Utilization of Information</li>
              <ol>
                <li>
                  Your personal data is employed to furnish and enhance{' '}
                  {appConfig.APP_NAME}. This encompasses payment processing,
                  identifying copyright infringement, and communication
                  regarding your account.
                </li>
                <li>
                  Additionally, we analyze usage trends, evaluate service
                  effectiveness, and develop new features using this
                  information.
                </li>
              </ol>
              <li className="fw-semibold">
                Information Sharing and Disclosure
              </li>
              <ol>
                <li>
                  Personal data may be shared with third-party service providers
                  who aid in delivering {appConfig.APP_NAME} services, such as
                  Stripe, a secure and encrypted payment processor. None of your
                  payment information is retained on our servers; it is securely
                  managed by Stripe.
                </li>
                <li>
                  We may disclose your data when required by law or in response
                  to a lawful request, like a subpoena or court order.
                </li>
              </ol>
              <li className="fw-semibold">Data Security</li>
              <ol>
                <li>
                  Reasonable measures are implemented to safeguard personal data
                  from unauthorized access, disclosure, alteration, or
                  destruction.
                </li>
                <li>
                  Please note, while we strive for security, absolute protection
                  cannot be guaranteed over the internet or electronic storage.
                </li>
              </ol>
              <li className="fw-semibold">Data Retention</li>
              <ol>
                <li>
                  Personal data is retained for as long as necessary to fulfill
                  the purposes outlined in this Privacy Policy, unless a longer
                  period is mandated by law.
                </li>
                <li>
                  You can request deletion of your account and associated
                  personal data by contacting us at{' '}
                  <span className="text-primary">
                    {' '}
                    <a
                      href="mailto:support@noleaks.com"
                      rel="noreferrer"
                      target="_blank"
                      className="text-decoration-none"
                    >
                      support@noleaks.com
                    </a>
                  </span>
                  .
                </li>
              </ol>
              <li className="fw-semibold">Third-Party Links and Services</li>
              <ol>
                <li>
                  {appConfig.APP_NAME} may contain links to third-party websites
                  or services not operated by us. We bear no responsibility for
                  the privacy practices or content of these external sites.
                </li>
              </ol>
              <li className="fw-semibold">Age Policy</li>
              <ol>
                <li>
                  {appConfig.APP_NAME} is not intended for those under 18.
                </li>
              </ol>
              <li className="fw-semibold">Changes to this Privacy Policy</li>
              <ol>
                <li>
                  This Privacy Policy may be updated periodically. Any
                  alterations will be communicated by posting the revised policy
                  on this page.
                </li>
              </ol>
              <li className="fw-semibold">Contact Us</li>
              <ol>
                <li>
                  For queries or concerns regarding this Privacy Policy or our
                  privacy practices, please contact us at{' '}
                  <span className="text-primary">
                    {' '}
                    <a
                      href="mailto:support@noleaks.com"
                      rel="noreferrer"
                      target="_blank"
                      className="text-decoration-none"
                    >
                      support@noleaks.com
                    </a>
                  </span>
                  .
                </li>
              </ol>
            </ol>
            <h5 className="py-5">
              By utilizing {appConfig.APP_NAME}, you signify your comprehension,
              acceptance, and adherence to this Privacy Policy.
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrivacyPage;
