import EyeIcon from '@assets/icons/EyeIcon';
import EyeSlashIcon from '@assets/icons/EyeSlashIcon';
import { useState } from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';

const PasswordField: React.FC<Omit<FormControlProps, 'type'>> = ({
  ...props
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="position-relative">
      <FormControl type={show ? 'text' : 'password'} {...props} />
      <div
        className="position-absolute"
        style={{ top: '50%', right: 20, transform: `translateY(-50%)` }}
        role="button"
        onClick={() => setShow(!show)}
      >
        {show ? <EyeSlashIcon /> : <EyeIcon />}
      </div>
    </div>
  );
};

export default PasswordField;
