import React from 'react';
import { Card } from 'react-bootstrap';
import { GETSTARTEDCARD } from 'types/creatorImage';

const GetStartedCard: React.FC<GETSTARTEDCARD> = ({ image, description }) => {
  return (
    <Card className="border-0 bg-transparent">
      <Card.Img variant="top" className="rounded-4" src={image} />
      <Card.Body className="m-0 px-0">
        <Card.Text className="fw-semibold">{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default GetStartedCard;
