import { useState, useEffect } from 'react';
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400, // Include this only if you're using Bootstrap 5
};
const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<
    'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  >('xs');

  useEffect(() => {
    const calculateBreakpoint = () => {
      const width = window.innerWidth;
      if (width < breakpoints.sm) {
        setBreakpoint('xs');
      } else if (width < breakpoints.md) {
        setBreakpoint('sm');
      } else if (width < breakpoints.lg) {
        setBreakpoint('md');
      } else if (width < breakpoints.xl) {
        setBreakpoint('lg');
      } else if (width < breakpoints.xxl) {
        setBreakpoint('xl');
      } else {
        setBreakpoint('xxl'); // Only if Bootstrap 5 is used
      }
    };

    window.addEventListener('resize', calculateBreakpoint);
    calculateBreakpoint(); // Initial call

    return () => window.removeEventListener('resize', calculateBreakpoint);
  }, []);

  return {
    breakpoint,
    isMobile: breakpoint === 'sm' || breakpoint === 'xs',
  };
};

export default useBreakpoint;
