import * as XLSX from 'xlsx';

export type CSVParserResultType = {
  isAcceptable: boolean;
  unacceptableReason: string;
  results: any[];
};

/**
 * parse an excel file
 * @param  {File} file
 * @returns Promise
 */
function parseXlsxFile(file: File, sheetNo = 0) {
  return new Promise<string[][]>((resolver) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event: ProgressEvent<FileReader>) => {
      const workbook: XLSX.WorkBook = XLSX.read(event?.target?.result, {
        type: 'binary',
      });
      const sheet = workbook.SheetNames[sheetNo];
      const parsedRowData = XLSX.utils.sheet_to_json<string[]>(
        workbook.Sheets[sheet],
        {
          header: 1,
          raw: true,
        },
      );
      return resolver(parsedRowData);
    });
    reader.readAsBinaryString(file);
  });
}

/**
 * provides a common interface for CSV parser
 * @param  {File} file
 */
async function parseExcelFile(
  file: File,
  parseColumnValue: (
    columnName: string,
    row: string,
  ) => string | number | boolean | object | null | undefined,
  columnSlugMap: { [key: string]: string },
  requiredColumns: string[] = [],
  sheetNo = 0,
): Promise<CSVParserResultType> {
  const type = file.type;
  const [, fileExtension] = file.name.split('.');
  let unacceptableReason = '';
  let isAcceptable = true;
  let columnSlugs: string[] = [];
  if (type !== 'text/csv' || fileExtension !== 'csv') {
    // isAcceptable = false
    // unacceptableReason = 'File format doest not match'
  }
  const results: any[] = [];
  try {
    const parsedData: string[][] = await parseXlsxFile(file, sheetNo);
    if (!parsedData || parsedData.length <= 1) {
      isAcceptable = false;
      unacceptableReason = 'No data found';
    } else {
      columnSlugs = parsedData[0].map((item) => columnSlugMap[item] || item);
      const missedColumns: string[] = [];
      isAcceptable = !requiredColumns.some((requiredColumn) => {
        const rt = !columnSlugs.find(
          (columnName) => columnName === requiredColumn,
        );
        if (rt) {
          missedColumns.push(requiredColumn);
        }
        return !!rt;
      });
      if (isAcceptable) {
        parsedData.forEach((parsedRow, i) => {
          if (i === 0) return;
          let row = {};
          columnSlugs.forEach((columnName, j) => {
            if (j >= parsedRow.length) return;
            const columnValue = `${parsedRow[j] || ''}`.trim();
            try {
              row = {
                ...row,
                [columnName]: parseColumnValue(columnName, columnValue),
              };
            } catch (err) {}
          });

          if (Object.keys(row).length > 0) {
            results.push(row);
          }
        });
        if (results.length === 0) {
          isAcceptable = false;
          unacceptableReason = 'No data found';
        }
      } else {
        unacceptableReason =
          'One or more required columns are missing: (' +
          missedColumns.join(', ') +
          ')';
      }
    }
  } catch (err: any) {
    isAcceptable = false;
    unacceptableReason = err.message;
  }

  return {
    isAcceptable,
    unacceptableReason,
    results,
  };
}

const fileHelper = {
  parseExcelFile,
};

export default fileHelper;
