import { NavLink } from 'react-router-dom';
import {
  BillingIcon,
  DashboardIcon,
  ReportsIcon,
  SettingsIcon,
} from '@assets/icons';
import appRoutes from '@constants/routes.app';
import { Menu } from './DashboardAsideBar';
import clsx from 'clsx';
import FlowerCheckIcon from '@assets/icons/FlowerCheckIcon';

export const menus: Menu[] = [
  {
    icon: <DashboardIcon />,
    title: 'Dashboard',
    link: appRoutes.dashboard,
  },
  {
    icon: <ReportsIcon />,
    title: 'Reports',
    link: appRoutes.reports,
  },
  {
    icon: <FlowerCheckIcon />,
    title: 'Whitelist',
    link: appRoutes.whitelistedDomains,
  },
  {
    icon: <BillingIcon />,
    title: 'Billing',
    link: appRoutes.billing,
  },
  {
    icon: <SettingsIcon />,
    title: 'Accounts',
    link: appRoutes.accounts,
  },
];
const DashboardStickyMenu: React.FC = () => {
  return (
    <div className="sticky-menu shadow-lg">
      {menus.map((menu, id) => (
        <NavLink
          key={id}
          className={({ isActive }) => clsx('menu-item', isActive && 'active')}
          to={menu.link}
        >
          {menu.icon}
          {menu.title}
        </NavLink>
      ))}
    </div>
  );
};

export default DashboardStickyMenu;
