const ReportsIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
      <defs>
        <filter id="Filter_0">
          <feFlood
            floodColor="rgb(106, 170, 249)"
            floodOpacity="1"
            result="floodOut"
          />
          <feComposite
            operator="atop"
            in="floodOut"
            in2="SourceGraphic"
            result="compOut"
          />
          <feBlend mode="normal" in="compOut" in2="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#Filter_0)">
        <path
          fillRule="evenodd"
          fill="rgb(56, 68, 85)"
          d="M15.971,21.865 L15.718,21.870 L6.580,21.870 C2.925,21.870 0.394,19.335 0.281,15.562 L0.277,15.302 L0.277,6.721 C0.277,2.908 2.706,0.264 6.320,0.142 L6.574,0.138 L14.293,0.138 C14.914,0.138 15.418,0.643 15.418,1.263 C15.418,1.823 14.1000,2.303 14.446,2.378 L14.316,2.388 L6.580,2.388 C4.172,2.388 2.620,3.958 2.531,6.485 L2.526,6.727 L2.526,15.296 C2.526,17.905 3.958,19.518 6.356,19.615 L6.585,19.619 L15.712,19.619 C18.120,19.619 19.672,18.054 19.762,15.532 L19.766,15.292 L19.766,7.828 C19.766,7.208 20.270,6.703 20.890,6.703 C21.451,6.703 21.929,7.121 22.003,7.676 L22.013,7.805 L22.014,15.296 C22.014,19.104 19.586,21.744 15.971,21.865 ZM16.955,9.012 L13.833,13.043 C13.490,13.482 12.869,13.607 12.387,13.331 L12.269,13.255 L9.518,11.094 L7.033,14.322 C6.815,14.606 6.479,14.759 6.138,14.759 C5.952,14.759 5.766,14.714 5.596,14.620 L5.475,14.541 C5.018,14.190 4.890,13.572 5.160,13.088 L5.237,12.969 L8.428,8.821 C8.768,8.379 9.389,8.254 9.875,8.530 L9.993,8.608 L12.747,10.769 L15.163,7.652 C15.544,7.163 16.250,7.074 16.740,7.452 C17.178,7.792 17.303,8.412 17.033,8.894 L16.955,9.012 Z"
        />
      </g>
    </svg>
  );
};

export default ReportsIcon;
