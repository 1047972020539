import { CREATORIMAGE, PLATFORMCARD, GETSTARTEDCARD } from 'types/creatorImage';

export const CREATOR_IMAGE: Array<CREATORIMAGE> = [
  {
    id: 1,
    img: '/assets/images/creator_youtube.png',
    width: '119px',
    height: '50px',
  },
  {
    id: 2,
    img: '/assets/images/creator_google.png',
    width: '152px',
    height: '50px',
  },
  {
    id: 3,
    img: '/assets/images/creator_twitter.png',
    width: '57px',
    height: '46px',
  },
  {
    id: 4,
    img: '/assets/images/creator_onlyfans.png',
    width: '240px',
    height: '50px',
  },
  {
    id: 5,
    img: '/assets/images/creator_instagram.png',
    width: '57px',
    height: '46px',
  },
  {
    id: 6,
    img: '/assets/images/creator_youtube.png',
    width: '119px',
    height: '50px',
  },
  {
    id: 7,
    img: '/assets/images/creator_google.png',
    width: '152px',
    height: '50px',
  },
  {
    id: 8,
    img: '/assets/images/creator_twitter.png',
    width: '57px',
    height: '46px',
  },
  {
    id: 9,
    img: '/assets/images/creator_onlyfans.png',
    width: '240px',
    height: '50px',
  },
  {
    id: 10,
    img: '/assets/images/creator_instagram.png',
    width: '57px',
    height: '46px',
  },
];

export const PLATFORM_CARD: Array<PLATFORMCARD> = [
  {
    title: 'Performance',
    subTitle:
      'Our algorithms tirelessly scour the web, at every instance of infringement',
    image: '/assets/images/performance.png',
  },
  {
    title: 'Reporting',
    subTitle:
      'Gain valuable insights into the impact of our platform on your digital assets.',
    image: '/assets/images/reports.png',
  },
  {
    title: 'Removals',
    subTitle:
      'Our integration with Google removal service ensures content is swiftly removed',
    image: '/assets/images/growth.png',
  },
];

export const GET_STARTED_CARD: Array<GETSTARTEDCARD> = [
  {
    image: 'assets/images/getstarted_1.png',
    description: 'Create your account',
  },
  {
    image: 'assets/images/getstarted_2.png',
    description: 'Enter your page info',
  },
  {
    image: 'assets/images/getstarted_3.png',
    description: 'Automatic + manual takedowns',
  },
];

export const GET_STARTED_CARD_MOBILE: Array<GETSTARTEDCARD> = [
  {
    image: 'assets/images/mobile/getstarted_1.png',
    description: 'Create your account',
  },
  {
    image: 'assets/images/mobile/getstarted_2.png',
    description: 'Enter your page info',
  },
  {
    image: 'assets/images/mobile/getstarted_3.png',
    description: 'Automatic + manual takedowns',
  },
];
