import CloseIcon from '@assets/icons/CloseIcon';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  Modal,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import UserJson from 'types/user.type';
type UpdateWhitelistDomainModalProps = {
  domain?: UserJson.Domain;
  open: boolean;
  onClose: (refresh?: boolean) => void;
};

type FormikData = UserJson.Domain;

const initialValues: FormikData = {
  id: 0,
  domain_url: '',
};
const UpdateWhitelistDomainModal: React.FC<UpdateWhitelistDomainModalProps> = ({
  domain,
  open,
  onClose,
}) => {
  const { isMobile } = useBreakpoint();
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string>('');
  const [domains, setDomains] = useState<FormikData[]>([{ ...initialValues }]);
  async function handleSubmit() {
    setStatus('');
    setSubmitting(true);
    try {
      if (domain?.id) {
        await axiosInstance.put('/api/profile/whitelist-domain/update', {
          id: domain.id,
          domain_url: domains[0].domain_url,
        });
      } else {
        await axiosInstance.post('/api/profile/whitelist-domain/bulk-add', {
          domains: domains.map((item) => item.domain_url),
        });
      }
      onClose(true);
      resetForm();
    } catch (err: any) {
      const errMsg =
        err.response.data.errors?.[0]?.domain_url ||
        'The field may not be blank.';

      setStatus(errMsg);
    }
    setSubmitting(false);
  }

  useEffect(() => {
    if (domain) {
      setDomains([
        {
          id: domain.id,
          domain_url: domain.domain_url || '',
        },
      ]);
    } else {
      setDomains([{ ...initialValues }]);
    }
    setStatus('');
  }, [domain]);
  function resetForm() {
    setStatus('');
    setDomains([{ ...initialValues }]);
  }

  function handleUpdate(id: number, data: Partial<FormikData>) {
    setDomains(
      [...domains].map((dm, index) =>
        id === index
          ? {
              ...dm,
              ...data,
            }
          : dm,
      ),
    );
  }
  return (
    <Modal show={open} onHide={() => onClose(false)} size="lg">
      <Modal.Header className="px-4 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          {domain ? 'Update Whitelisted Domain' : 'Add to Whitelisted Domains'}
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="py-4 py-md-5 pt-0 pt-md-0">
        <div
          className="overflow-auto px-4 px-md-5"
          style={{ maxHeight: 'calc(100vh - 270px)' }}
        >
          {domains.map((dm, id) => (
            <div key={id}>
              <FormGroup className="mb-4 mt-4">
                <FormControl
                  size={isMobile ? undefined : 'lg'}
                  placeholder="Whitelisted Content URL"
                  value={dm.domain_url}
                  onChange={(e) =>
                    handleUpdate(id, { domain_url: e.target.value })
                  }
                />
              </FormGroup>
              {!domain && (
                <div className="d-flex align-items-center mb-3">
                  <hr className="flex-grow-1" />
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle cursor-pointer"
                    style={{
                      width: 26,
                      height: 26,
                      background: '#f7f3f3',
                      color: '#bcc0c7',
                    }}
                    onClick={() => {
                      if (id < domains.length - 1) {
                        const newDomains = [...domains].filter(
                          (v, index) => index !== id,
                        );
                        setDomains(newDomains);
                      } else {
                        setDomains([...domains, { ...initialValues }]);
                      }
                    }}
                  >
                    {id < domains.length - 1 ? '-' : '+'}
                  </div>
                  <hr className="flex-grow-1" />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="px-4 px-md-5">
          {status && <Alert variant="danger">{status}</Alert>}
          <Button
            className="w-100"
            size={isMobile ? undefined : 'lg'}
            disabled={submitting}
            onClick={() => handleSubmit()}
          >
            {submitting && <Spinner size="sm" className="me-2" />}
            Save & Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateWhitelistDomainModal;
