const appRoutes = {
  home: '/',

  signin: '/signin',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',

  selectUserType: '/select-user-type',
  agencyInfo: '/agency-info',
  userInfo: '/user-info',
  checkout: '/checkout',

  dashboard: '/dashboard',
  reports: '/reports',
  billing: '/billing',
  billingReceipts: '/billing/receipts',
  accounts: '/accounts',
  whitelistedDomains: '/whitelisted-domains',
  faq: '/faq',

  terms: '/terms-of-services',
  privacy: '/privacy-policy',
};

export const protectedRoutes = [
  appRoutes.selectUserType,
  appRoutes.agencyInfo,
  appRoutes.userInfo,
  appRoutes.checkout,

  appRoutes.dashboard,
  appRoutes.reports,
  appRoutes.billing,
  appRoutes.billingReceipts,
  appRoutes.accounts,
  appRoutes.whitelistedDomains,
  appRoutes.faq,
];
export default appRoutes;
