import AppTypes from '@models/app.type';

const Graph1Icon: React.FC<AppTypes.Icon & { value?: number }> = ({
  width = 101,
  height = 28,
  color = 'rgb(252, 153, 170)',
  value,
}) => {
  return (
    <div className="position-relative d-flex gap-1" style={{ width, height }}>
      {[0, 1, 2, 3].map((v) => (
        <div
          key={v}
          style={{
            background: '#f9f5f5',
            flexGrow: 1,
            height: '100%',
          }}
        />
      ))}
      {value && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          className="position-absolute top-0 left-0 right-0 bottom-0"
          viewBox="0 0 101 28"
        >
          <path
            fillRule="evenodd"
            stroke={color}
            strokeWidth="2px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            fill="none"
            d="M1.1000,24.1000 C20.449,8.296 37.865,-0.718 52.000,4.000 C57.237,5.748 60.041,8.821 65.000,11.000 C74.282,15.079 85.256,15.378 98.000,11.1000 "
          />
        </svg>
      )}
    </div>
  );
};

export default Graph1Icon;
