import React from 'react';
import { Card, Image, Stack } from 'react-bootstrap';
import { PLATFORMCARD } from 'types/creatorImage';
import { RiArrowRightWideLine } from 'react-icons/ri';

// interface PlatformCardProp {
//   title: string;
//   subTitle: string;
//   image: string;
// }

const PlatformCard: React.FC<PLATFORMCARD> = ({ title, subTitle, image }) => {
  return (
    <Card className="rounded-4" style={{ border: '2px solid #DEE3EA' }}>
      <Stack direction="vertical" gap={2} className="p-3 rounded-4">
        <div className="d-flex justify-content-start align-items-center gap-1">
          <h4 className="m-0">{title}</h4>
          <RiArrowRightWideLine color="#797676" />
        </div>
        <p style={{ color: '#797676', fontSize: '15px' }}>{subTitle}</p>
        <div
          className="d-flex justify-content-center align-items-center rounded-4 py-4 mt-3 mb-2"
          style={{ backgroundColor: '#D4E6FC' }}
        >
          <Image src={image} width={200} height={150} />
        </div>
      </Stack>
    </Card>
  );
};

export default PlatformCard;
