import AppTypes from '@models/app.type';

const SearchIcon: React.FC<AppTypes.Icon> = ({
  width = 14,
  height = 14,
  color = 'rgb(137, 137, 137)',
}) => {
  return (
    <svg height={width} width={height} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        fill={color}
        d="M13.342,11.928 L11.921,13.336 L8.630,10.015 C7.741,10.633 6.664,10.1000 5.500,10.1000 C2.463,10.1000 0.000,8.538 0.000,5.500 C0.000,2.462 2.463,0.000 5.500,0.000 C8.537,0.000 10.1000,2.462 10.1000,5.500 C10.1000,6.650 10.645,7.716 10.041,8.598 L13.342,11.928 Z"
      />
    </svg>
  );
};

export default SearchIcon;
