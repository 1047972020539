import appConfig from '@constants/appConfig.app';
import React from 'react';
import { Col, Container, Image, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className=" pt-5 pb-4 footer_section">
      <Container fluid={'xxl'}>
        <Row className="px-3 justify-content-between  align-items-center ">
          <Col lg={4}>
            <Stack direction="vertical" gap={3}>
              <Image
                src="/logo.png"
                alt={`${appConfig.APP_NAME} Logo`}
                fluid
                width={150}
              />
              <p className="fw-semibold">
                Empowering digital creators by safeguarding their intellectual
                property rights, our mission is to combat copyright infringement
                through innovative technology solutions.
              </p>
            </Stack>
          </Col>
          <Col lg={6}>
            <Row className="justify-content-around links">
              <Col lg={3} xxs={5} xs={5} sm={5}>
                <h5 className="fw-semibold">Company</h5>
                <Stack direction="vertical" gap={2}>
                  <Link to="" className=" text-muted">
                    Careers
                  </Link>
                  <Link to="" className="link text-muted">
                    About
                  </Link>
                  {/* <Link to="">User Cases</Link> */}
                </Stack>
              </Col>
              <Col lg={3} xxs={5} xs={5} sm={5}>
                <h5 className="fw-semibold">Creators</h5>
                <Stack direction="vertical" gap={2}>
                  <Link to="" className="link text-muted">
                    How it works
                  </Link>
                  <Link to="" className="link text-muted">
                    Pricing
                  </Link>
                  <Link to="" className="link text-muted">
                    FAQ
                  </Link>
                </Stack>
              </Col>
              <Col lg={3} xxs={12} xs={12} sm={12} className="">
                <h5 className="fw-semibold">Legal</h5>
                <Stack direction="vertical" gap={2}>
                  <Link to="/terms-of-services" className="link text-muted">
                    Terms of Services
                  </Link>
                  <Link to="/privacy-policy" className="link text-muted">
                    Privacy Policy
                  </Link>
                  <Link to="" className="link text-muted">
                    Cookies Policy
                  </Link>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-3 pt-5">
          <Col lg={4}>
            <p className="text-muted fw-semibold">
              &copy; {new Date().getFullYear()} {appConfig.APP_NAME}. All rights
              reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
