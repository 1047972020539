import UserAvatar from '@components/UserAvatar';
import ConfirmModal from '@components/modals/ConfirmModal';
import UpdateAccountInfoModal from '@components/modals/UpdateAccountInfoModal';
import useAuth from '@hooks/useAuth';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, FormCheck, Spinner, Table } from 'react-bootstrap';
import UserJson from 'types/user.type';

const Accounts: React.FC = () => {
  const {
    refetchProfiles: refetchGlobalProfiles,
    refetchPaymentDetails,
    refetchUser,
    profiles: globalProfiles,
  } = useAuth();
  const { isMobile } = useBreakpoint();
  const [profiles, setProfiles] = useState<UserJson.Profile[]>([]);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState<number[]>([]);
  const [account, setAccount] = useState<UserJson.Profile | undefined>(
    undefined,
  );

  const size = isMobile ? 5 : 10;
  const refetchProfiles = async () => {
    setLoadingProfiles(true);
    try {
      const { data } = await axiosInstance.get(
        `/api/profile/all?page=${page}&page_size=${size}`,
      );
      setProfiles(data.data.profiles || []);
      setTotal(data.data.total || 0);
    } catch (err) {}
    setLoadingProfiles(false);
  };
  const totalPage = useMemo(() => Math.floor((total - 1) / size) + 1, [total]);
  useEffect(() => {
    refetchProfiles();
  }, [size, page, globalProfiles]);

  const [deleting, setDeleting] = useState(false);
  async function handleRemove() {
    setDeleting(true);
    try {
      for (const id of selectedAccountIds) {
        await axiosInstance.delete(`/api/profile/delete?id=${id}`);
      }
      await Promise.all([
        refetchProfiles(),
        refetchGlobalProfiles(),
        refetchPaymentDetails(),
        refetchUser(),
      ]);
      setSelectedAccountIds([]);
    } catch (err) {}
    setDeleting(false);
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2 mb-md-4">
        <h1 className="fs-xxl">Accounts</h1>
      </div>
      <div className="d-flex gap-4 mb-4">
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => {
            setAccount(undefined);
            setOpen(true);
          }}
        >
          Add Account
        </Button>
        {!isMobile && (
          <Button
            size="sm"
            variant="outline-danger"
            disabled={!selectedAccountIds.length || deleting}
            onClick={() => setShowConfirmModal(true)}
          >
            {deleting && <Spinner size="sm" className="me-2" />}
            Remove Account
          </Button>
        )}
      </div>
      <div className="d-flex flex-wrap justify-content-between mb-4">
        <div>
          Showing {profiles.length} of {total}
        </div>
        <div className="d-flex gap-2">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setPage(page - 1)}
            disabled={page <= 1}
          >
            Previous
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setPage(page + 1)}
            disabled={page >= totalPage}
          >
            Next
          </Button>
        </div>
      </div>
      {loadingProfiles && (
        <div className="p-5 d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      {!isMobile && (
        <Table className="bg-transparent" responsive>
          <tbody>
            {!loadingProfiles &&
              profiles.map((account, id) => (
                <tr key={id}>
                  <td className="bg-transparent" valign="middle" width={20}>
                    <FormCheck
                      checked={selectedAccountIds.includes(account.id || 0)}
                      onChange={(e) => {
                        let newSelection = [...selectedAccountIds];
                        if (e.target.checked) {
                          newSelection.push(account.id || 0);
                        } else {
                          newSelection = newSelection.filter(
                            (it) => it !== account.id,
                          );
                        }
                        setSelectedAccountIds(newSelection);
                      }}
                    />
                  </td>
                  <td
                    className="bg-transparent"
                    valign="middle"
                    width={80}
                    align="center"
                  >
                    <UserAvatar
                      size={74}
                      imgUrl={account.picture}
                      colorIndex={id}
                    />
                  </td>
                  <td className="bg-transparent" valign="middle">
                    <p className="text-nowrap">{account.name}</p>
                    <p className="text-nowrap">{account.category}</p>
                    {account.instagram_username && (
                      <p className="fs-sm">
                        <b className="fw-medium">Instagram: </b>
                        <span className="text-primary">
                          @{account.instagram_username}
                        </span>
                      </p>
                    )}
                    {account.twitter_username && (
                      <p className="fs-sm">
                        <b className="fw-medium">Twitter: </b>
                        <span className="text-primary">
                          @{account.twitter_username}
                        </span>
                      </p>
                    )}
                  </td>
                  <td className="bg-transparent" valign="middle">
                    <p>
                      <b className="fw-medium">Alias Names:</b>{' '}
                      {account.aliases}
                    </p>
                    <p>
                      <b className="fw-medium">Exclusive Content URL:</b>{' '}
                      {account.page_url}
                    </p>
                    <p>
                      <b className="fw-medium">Keywords:</b>{' '}
                      {account.custom_search_keywords}
                    </p>
                  </td>
                  <td className="bg-transparent" valign="middle" align="right">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        setOpen(true);
                        setAccount(account);
                      }}
                    >
                      Update
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      {isMobile && (
        <div className="d-flex flex-column gap-2">
          {!loadingProfiles &&
            profiles.map((account, id) => (
              <Card key={id} className="py-3 px-3 border-0 shadow-sm">
                <div className="d-flex align-items-center gap-3">
                  <UserAvatar
                    size={48}
                    imgUrl={account.picture}
                    colorIndex={id}
                  />
                  <div>
                    <p>{account.name}</p>
                    <p>{account.category}</p>
                    {account.instagram_username && (
                      <p className="fs-sm">
                        <b className="fw-medium">Instagram: </b>
                        <span className="text-primary">
                          @{account.instagram_username}
                        </span>
                      </p>
                    )}
                    {account.twitter_username && (
                      <p className="fs-sm">
                        <b className="fw-medium">Twitter: </b>
                        <span className="text-primary">
                          @{account.twitter_username}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  <p>
                    <b className="fw-medium">Alias Names:</b> {account.aliases}
                  </p>
                  <p>
                    <b className="fw-medium">Exclusive Content URL:</b>{' '}
                    {account.page_url}
                  </p>
                  <p>
                    <b className="fw-medium">Keywords:</b>{' '}
                    {account.custom_search_keywords}
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      setOpen(true);
                      setAccount(account);
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                      setSelectedAccountIds([account.id]);
                      setShowConfirmModal(true);
                    }}
                    disabled={!deleting && account.id === selectedAccountIds[0]}
                  >
                    Remove
                  </Button>
                </div>
              </Card>
            ))}
        </div>
      )}
      <UpdateAccountInfoModal
        open={open}
        onClose={(refresh) => {
          if (refresh) {
            refetchGlobalProfiles();
            refetchPaymentDetails();
            refetchUser();
          }
          setOpen(false);
        }}
        account={account}
      />
      <ConfirmModal
        open={showConfirmModal}
        onConfirm={(confirm) => {
          setShowConfirmModal(false);
          if (confirm) handleRemove();
        }}
      />
    </div>
  );
};

export default Accounts;
