import CloseIcon from '@assets/icons/CloseIcon';
import UserAvatar from '@components/UserAvatar';
import useAuth from '@hooks/useAuth';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FormText,
  Modal,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import UserJson from 'types/user.type';
type UpdateAccountInfoModalProps = {
  account?: UserJson.Profile;
  open: boolean;
  onClose: () => void;
};

type FormikData = Pick<UserJson.Account, 'name' | 'avatar'>;

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
});
const initialValues: FormikData = {
  name: '',
  avatar: '',
};
const UpdateProfileModal: React.FC<UpdateAccountInfoModalProps> = ({
  open,
  onClose,
}) => {
  const { isMobile } = useBreakpoint();
  const { user, refetchUser } = useAuth();
  const [file, setFile] = useState<File | undefined>(undefined);
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus }) => {
      setStatus('');
      try {
        const formData = new FormData();
        formData.append('name', values.name || '');
        if (file) {
          formData.append('avatar', file, file.name.slice(-99));
        }
        await axiosInstance.put('/api/profile/account/update', formData);
        await refetchUser();
        setFile(undefined);
        onClose();
      } catch (err: any) {
        const errMsg = Object.values(err.response.data.errors).join(',');
        setStatus(`Failed to update account: ${errMsg}`);
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || '',
        avatar: user.avatar || '',
      });
      setFile(undefined);
    }
  }, [user]);

  return (
    <Modal show={open} onHide={onClose} size="lg">
      <Modal.Header className="px-4 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          Update Profile
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="py-4 py-md-5 pt-0 pt-md-0">
        <UserAvatar
          size={85}
          editable
          imgUrl={formik.values.avatar}
          onChangeFile={(file) => {
            if (file) {
              setFile(file);
              const reader = new FileReader();
              reader.onloadend = () => {
                formik.setFieldValue('avatar', reader.result as string);
              };
              reader.readAsDataURL(file);
            }
          }}
        />
        <FormGroup className="mb-4 mt-4">
          <FormControl
            size={isMobile ? undefined : 'lg'}
            placeholder="Full name"
            {...formik.getFieldProps('name')}
            isInvalid={formik.touched.name && !!formik.errors.name}
          />
          {formik.touched.name && formik.errors.name && (
            <FormText className="text-danger">{formik.errors.name}</FormText>
          )}
        </FormGroup>

        {formik.status && <Alert variant="danger">{formik.status}</Alert>}
        <Button
          className="w-100"
          size={isMobile ? undefined : 'lg'}
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
          Update Profile Information
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateProfileModal;
