import appRoutes from '@constants/routes.app';
import { Button, Container, Navbar } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import Footer from './Footer';
import AppLogo from '@components/AppLogo';
import useAuth from '@hooks/useAuth';

const DefaultLayout: React.FC = () => {
  const { authUser } = useAuth();
  return (
    <div>
      <Navbar expand="lg" className="bg-white shadow-sm">
        <Container fluid={'xxl'}>
          <div className="d-flex align-items-center justify-content-between flex-grow-1 px-lg-4 px-sm-0 px-xs-0">
            <AppLogo />
            <div className="d-flex justify-content-center align-items-center  gap-3">
              {authUser ? (
                <Link to={appRoutes.dashboard} className="fs-6 fw-semibold">
                  <Button variant="primary" size="sm" className="fs-6">
                    Go to Dashboard
                  </Button>
                </Link>
              ) : (
                <>
                  <Link to={appRoutes.signin} className="fs-6 fw-semibold">
                    Sign in
                  </Link>
                  <Link to={appRoutes.signup}>
                    <Button variant="primary" size="sm" className="fs-6">
                      Get Started
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </Container>
      </Navbar>
      <main className="bg-white" style={{ minHeight: `calc(100vh - 70px)` }}>
        <Outlet />
        <Footer />
      </main>
    </div>
  );
};

export default DefaultLayout;
