import AppTypes from '@models/app.type';

const MinusIcon: React.FC<AppTypes.Icon> = ({
  width = 22,
  height = 3,
  color = '#6aaaf9',
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        fillRule="evenodd"
        fill={color}
        d="M1.1000,-0.000 L19.1000,-0.000 C21.105,-0.000 21.1000,0.895 21.1000,2.000 C21.1000,3.105 21.105,4.000 19.1000,4.000 L1.1000,4.000 C0.896,4.000 -0.000,3.105 -0.000,2.000 C-0.000,0.895 0.896,-0.000 1.1000,-0.000 Z"
      />
    </svg>
  );
};

export default MinusIcon;
