import SearchIcon from '@assets/icons/SearchIcon';
import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'react-bootstrap';
import AppLogo from '@components/AppLogo';
import useBreakpoint from '@hooks/useBreakpoint';
import clsx from 'clsx';
import { useState } from 'react';
import SubmitLinksModal from '@components/modals/SubmitLinksModal';
import UserIcon from '@assets/icons/UserIcon';
import LogoutIcon from '@assets/icons/LogoutIcon';
import SettingIcon from '@assets/icons/SettingIcon';
import { Link, useNavigate } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import authService from '@utils/auth-service';
import useAuth from '@hooks/useAuth';
import UserAvatar from '@components/UserAvatar';
import ReactSelect from 'react-select';
import faqs from '@mock/faq.mock';
import UpdateProfileModal from '@components/modals/UpdateProfileModal';
import ChangePasswordModal from '@components/modals/ChangePasswordModal';
import PasswordIcon from '@assets/icons/PasswordIcon';

type SearchOption = { label: string; value: string; aliases: string[] };
const searchOptions: SearchOption[] = [
  {
    label: 'Dashboard',
    value: appRoutes.dashboard,
    aliases: ['dashboard', 'overview', 'total', 'all', 'removed'],
  },
  {
    label: 'Reports',
    value: appRoutes.reports,
    aliases: ['reports', 'analytics', 'history', 'removed'],
  },
  {
    label: 'Billing',
    value: appRoutes.billing,
    aliases: [
      'billing',
      'cycle',
      'account',
      'profile',
      'payment',
      'plan',
      'unlimited',
      'manage',
      'premium',
      'cancel',
      'upgrade',
    ],
  },
  {
    label: 'Settings',
    value: appRoutes.accounts,
    aliases: [
      'settings',
      'preference',
      'account',
      'profile',
      'add',
      'remove',
      'update',
      'delete',
    ],
  },
  {
    label: 'FAQ',
    value: appRoutes.faq,
    aliases: [
      'faq',
      ...faqs
        .map((faq) => Object.values(faq).join(' '))
        .join(' ')
        .split(' '),
    ],
  },
];
type DashboardHeaderProps = {
  setExpanded: (v: boolean) => void;
  expanded: boolean;
};

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  expanded,
  setExpanded,
}) => {
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { user, paymentDetails } = useAuth();
  const [searchValue, setSearchValue] = useState<SearchOption | null>(null);
  const navigate = useNavigate();
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const { isMobile } = useBreakpoint();
  const isPremium = paymentDetails?.plan_type !== 'unlimited';
  return (
    <header className="dashboard-header px-3 px-md-5 shadow-sm">
      <div className="d-flex align-items-center gap-2 gap-md-4">
        {!expanded && <AppLogo />}
        {!isMobile && (
          <ReactSelect
            styles={{
              control: (provided) => ({
                ...provided,
                width: '300px',
              }),
            }}
            placeholder={
              <>
                <SearchIcon /> Search
              </>
            }
            value={searchValue}
            options={searchOptions}
            filterOption={(term, searchKey) => {
              return (
                term.label.toLowerCase().includes(searchKey.toLowerCase()) ||
                (term.data.aliases &&
                  term.data.aliases.some(
                    (alias) =>
                      alias.toLowerCase().includes(searchKey.toLowerCase()) ||
                      searchKey.toLowerCase().includes(alias.toLowerCase()),
                  ))
              );
            }}
            onChange={(item) => {
              if (item) {
                navigate(item?.value);
              }
              setSearchValue(item);
            }}
            isClearable
          />
        )}
      </div>
      <div
        className={clsx(
          'd-flex align-items-center',
          isMobile ? 'gap-3' : 'gap-4',
        )}
      >
        <Button size="sm" onClick={() => setOpenSubmitModal(true)}>
          + Submit Links
        </Button>
        {/* <AlertIcon /> */}
        <Dropdown>
          <DropdownToggle as="div" className="hide-caret cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <UserAvatar size={32} imgUrl={user?.avatar} />
              {!isMobile && (
                <div>
                  <p className="fs-sm">{user?.name}</p>
                  <p className="fs-xs text-secondary">{user?.email}</p>
                </div>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownHeader className="d-flex gap-2 align-items-start">
              <UserAvatar size={32} imgUrl={user?.avatar} />
              <div>
                <p className="fs-sm">{user?.name}</p>
                <p className="fs-xs text-secondary">{user?.email}</p>
                <p className="fs-xs text-secondary">
                  {paymentDetails?.used_links_text || ''}{' '}
                  {isPremium && (
                    <Link
                      to={appRoutes.billing}
                      className="text-primary text-decoration-underline"
                    >
                      Upgrade plan
                    </Link>
                  )}
                </p>
              </div>
            </DropdownHeader>
            <DropdownDivider />
            <DropdownItem
              as={Link}
              to={appRoutes.accounts}
              className="d-flex gap-2 align-items-center"
            >
              <SettingIcon />
              Accounts
            </DropdownItem>
            <DropdownItem
              className="d-flex gap-2 align-items-center"
              onClick={() => setShowProfileEditModal(true)}
            >
              <UserIcon />
              Profile
            </DropdownItem>
            <DropdownItem
              className="d-flex gap-2 align-items-center"
              onClick={() => setShowChangePasswordModal(true)}
            >
              <PasswordIcon />
              Change Password
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem
              className="d-flex gap-2 align-items-center"
              onClick={async () => {
                await authService.logout();
                navigate(appRoutes.home, { replace: true });
              }}
            >
              <LogoutIcon />
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <SubmitLinksModal
        open={openSubmitModal}
        onClose={() => setOpenSubmitModal(false)}
      />
      <UpdateProfileModal
        open={showProfileEditModal}
        onClose={() => setShowProfileEditModal(false)}
      />
      <ChangePasswordModal
        open={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      />
    </header>
  );
};

export default DashboardHeader;
