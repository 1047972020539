import CloseIcon from '@assets/icons/CloseIcon';
import appConfig from '@constants/appConfig.app';
import { defaultMonthly } from '@constants/prices.app';
import useAuth from '@hooks/useAuth';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import { useState } from 'react';
import {
  Alert,
  Button,
  FormGroup,
  FormLabel,
  Modal,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';

type UpgradePlanModalProps = {
  currentPlan?: 'premium' | 'unlimited';
  open: boolean;
  onClose: (refresh?: boolean) => void;
  onClickUpdatePayment: () => void;
};
const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  currentPlan = 'premium',
  open,
  onClose,
  onClickUpdatePayment,
}) => {
  const { paymentDetails } = useAuth();
  const { isMobile } = useBreakpoint();
  const { refetchUser } = useAuth();
  const [agree, setAgree] = useState(true);
  const [status, setStatus] = useState('');
  const [upgrading, setUpgrading] = useState(false);
  const isPremium = currentPlan === 'premium';
  async function handleUpgrade() {
    setStatus('');
    setUpgrading(true);
    try {
      await axiosInstance.post('/api/payment/change-plan-type/', {
        unlimited: isPremium,
      });
      onClose(true);
      await refetchUser();
    } catch (err) {
      setStatus('Could not upgrade plan.');
    }
    setUpgrading(false);
  }
  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header className="px-4 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          {isPremium ? 'Unlimited' : 'Premium'} Plan
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="p-4 p-md-5 pt-0 pt-md-0 d-flex gap-4 flex-column">
        {status && <Alert variant="danger">{status}</Alert>}
        <div>
          <p className="fs-lg fw-medium">Summary</p>
          <div className="d-flex justify-content-between">
            <p>
              <span className="text-capitalize">
                {isPremium ? 'Unlimited' : 'Premium'}
              </span>{' '}
              {appConfig.APP_NAME} Reports
            </p>
            <p>
              {isPremium
                ? '$1 per 100 links submitted'
                : `$${defaultMonthly}/mo`}
            </p>
          </div>
          <hr className="mt-1 mb-1" />
          <div className="d-flex justify-content-between mb-4">
            <p>Subtotal</p>
            <p>$0</p>
          </div>
          <div>
            {paymentDetails?.payment_method_details && (
              <>
                <p className="fw-medium">
                  Credit card number: **** **** ****{' '}
                  {paymentDetails.payment_method_details.last4}
                </p>
                <p className="fw-medium">
                  Expiration Date:{' '}
                  {`${paymentDetails.payment_method_details.exp_month < 10 ? '0' : ''}${paymentDetails.payment_method_details.exp_month} ${paymentDetails.payment_method_details.exp_year}`}
                </p>
                <p className="fw-medium">
                  Country:{' '}
                  {paymentDetails.payment_method_details.address?.country}
                </p>
              </>
            )}
            <p
              className="text-primary"
              role="button"
              onClick={() => onClickUpdatePayment()}
            >
              Update
            </p>
          </div>
        </div>
        <Button
          className="w-100"
          size={isMobile ? undefined : 'lg'}
          onClick={() => handleUpgrade()}
          disabled={upgrading}
        >
          {upgrading && <Spinner size="sm" className="me-3" />}
          Confirm & {isPremium ? 'Upgrade' : 'Downgrade'}
        </Button>

        <FormGroup>
          <div className="d-flex gap-3">
            <FormCheckInput
              id="agreeTerm"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            <FormLabel
              className="text-muted fs-sm cursor-pointer"
              htmlFor="agreeTerm"
            >
              I agree that I will be charged $1 per 100 links submitted to the
              billing method provided, with unlimited requests
            </FormLabel>
          </div>
        </FormGroup>
      </Modal.Body>
    </Modal>
  );
};
export default UpgradePlanModal;
