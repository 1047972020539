import ArrowDownIcon from '@assets/icons/ArrowDownIcon';
import MinusIcon from '@assets/icons/MinusIcon';
import faqs from '@mock/faq.mock';
import { useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';

const Faq: React.FC = () => {
  const [openId, setOpenId] = useState(0);
  return (
    <div>
      <h1 className="fs-xxl mb-0 mb-md-4">Frequently Asked Questions</h1>
      <div className="d-flex flex-column gap-4">
        {faqs.map((faq, id) => (
          <Card
            className="bg-white p-4 cursor-pointer"
            onClick={() => setOpenId(id)}
            key={id}
          >
            <div className="d-flex justify-content-between gap-5 mb-2">
              <p className="fs-lg fw-semibold">{faq.question}</p>
              <div>{openId === id ? <MinusIcon /> : <ArrowDownIcon />}</div>
            </div>

            <Collapse in={openId === id}>
              <div>{faq.answer}</div>
            </Collapse>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Faq;
