import appConfig from '@constants/appConfig.app';
import AppTypes from '@models/app.type';

const faqs: AppTypes.FAQ[] = [
  {
    question: `How does ${appConfig.APP_NAME} work?`,
    answer: `${appConfig.APP_NAME} utilizes advanced AI algorithms and facial recognition technology to systematically locate copyright infringement instances across the internet. Once identified, it automatically initiates ${appConfig.APP_NAME} takedown requests and explores legal alternatives for content removal.`,
  },
  {
    question: `What platforms does ${appConfig.APP_NAME} support?`,
    answer: `Our bot is designed to operate across a wide range of online platforms, including social media networks, websites, forums, and file-sharing platforms.`,
  },
  {
    question: `What is included in the free trial period?`,
    answer: `During the 7-day free trial, you’ll have full access to all features of ${appConfig.APP_NAME}, allowing you to experience its effectiveness firsthand in identifying and addressing copyright infringement instances.`,
  },
  {
    question: `How does pricing work after the free trial?`,
    answer: `After the free trial period, our pricing is $20 per month for regular subscriptions. However, if you opt for an annual subscription, priced at just $199, you’ll save 17% compared to the monthly plan, providing excellent value for continuous protection.`,
  },
  {
    question: `Can I cancel my subscription at any time?`,
    answer: `Yes, you can cancel your subscription at any time without any additional fees. Simply reach out to our customer support team, and we’ll guide you through the process.`,
  },
  {
    question: `What kind of support do you offer?`,
    answer: `We provide comprehensive customer support to assist you with any questions or issues you may encounter. Our support team is available via email and live chat to ensure a seamless experience.`,
  },
  {
    question: `Is my data secure with your service?`,
    answer: `Absolutely. We prioritize the security and privacy of our users’ data. All information processed by us is handled with strict confidentiality and in compliance with relevant data protection regulations.`,
  },
  {
    question: `How effective is ${appConfig.APP_NAME} in removing copyrighted content?`,
    answer: `${appConfig.APP_NAME} leverages cutting-edge technology to achieve high efficiency in identifying and removing copyrighted content. While we cannot guarantee 100% removal due to the dynamic nature of online content, our bot significantly streamlines the process and enhances the success rate of takedown requests. `,
  },
];
export default faqs;
