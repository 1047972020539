import React from 'react';
import {
  Alert,
  Button,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Image,
  Spinner,
} from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import { useFormik } from 'formik';
import AuthJson from 'types/auth.type';
import * as yup from 'yup';
import authService from '@utils/auth-service';
import useAuth from '@hooks/useAuth';
import PasswordField from '@components/PasswordField';

const initialValues: AuthJson.SigninInput = {
  email: '',
  password: '',
  rememberMe: false,
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});
const Signin: React.FC = () => {
  const { loading, user } = useAuth();
  const formik = useFormik<AuthJson.SigninInput>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus('');
      setSubmitting(true);
      try {
        await authService.login(values);
      } catch (error: any) {
        switch (error.code) {
          case 'auth/wrong-password':
            setStatus('The password is incorrect. Please try again.');
            break;
          case 'auth/user-not-found':
            setStatus('No user found with this email address.');
            break;
          default:
            setStatus('Invalid login attempt.');
        }
      }
      setSubmitting(false);
    },
  });

  async function handleSigninWithGoogle() {
    formik.setStatus('');
    try {
      await authService.signInWithGoogle();
    } catch (err) {
      console.log(err);
      formik.setStatus('Invalid login attempt.');
    }
  }
  if (!loading && user)
    return (
      <Navigate
        to={
          user.payment_method_id
            ? appRoutes.dashboard
            : appRoutes.selectUserType
        }
      />
    );
  return (
    <>
      <h2 className="fs-xl">Sign in</h2>
      {formik.status && <Alert variant="danger">{formik.status}</Alert>}
      <FormGroup className="">
        <FormLabel>Email</FormLabel>
        <FormControl
          placeholder="Email address"
          {...formik.getFieldProps('email')}
          isInvalid={formik.touched.email && !!formik.errors.email}
        />
        {formik.touched.email && !!formik.errors.email && (
          <FormText className="text-danger">{formik.errors.email}</FormText>
        )}
      </FormGroup>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <FormLabel>Password</FormLabel>
          <Link
            to={appRoutes.forgotPassword}
            className="text-primary text-decoration-underline"
          >
            Forgot password?
          </Link>
        </div>
        <PasswordField
          placeholder="Password"
          {...formik.getFieldProps('password')}
          isInvalid={formik.touched.password && !!formik.errors.password}
        />
        {formik.touched.password && !!formik.errors.password && (
          <FormText className="text-danger">{formik.errors.password}</FormText>
        )}
      </FormGroup>
      <FormCheck
        {...formik.getFieldProps('rememberMe')}
        id="remember-me"
        label="Remember me"
        className="cursor-pointer"
      />
      <Button
        className=""
        onClick={() => formik.handleSubmit()}
        disabled={formik.isSubmitting}
      >
        {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
        Sign in
      </Button>
      <hr />
      <Button
        className="d-flex gap-3 justify-content-center align-items-center border-secondary"
        variant="outline-dark"
        onClick={handleSigninWithGoogle}
      >
        <Image src="/assets/images/google.png" width={22} height={22} />
        Sign in with Google
      </Button>
      <p className="text-muted fs-sm text-center">
        If you don't have account, please create your account{' '}
        <Link to={appRoutes.signup} className="text-primary">
          here
        </Link>
      </p>
    </>
  );
};

export default Signin;
