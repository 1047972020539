import CloseIcon from '@assets/icons/CloseIcon';
import FileDropzone from '@components/FileDropzone';
import UserAvatar from '@components/UserAvatar';
import { defaultMonthly } from '@constants/prices.app';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import fileHelper from '@utils/file.util';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  FormCheck,
  FormControl,
  FormGroup,
  FormSelect,
  Modal,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import UserJson from 'types/user.type';

const columnSlugs = {
  fullName: 'name',
  category: 'category',
  page_url: 'page_url',
  aliases: 'aliases',
  instagram_username: 'instagram_username',
  twitter_username: 'twitter_username',
  keywords: 'keywords',
};

const columnNamesMap = {
  'Full Name*': columnSlugs.fullName,
  'Creator or Celebrity*': columnSlugs.category,
  'Exclusive Content URL*': columnSlugs.page_url,
  'Also Known As': columnSlugs.aliases,
  'Instagram @': columnSlugs.instagram_username,
  'Twitter @': columnSlugs.twitter_username,
  Keywords: columnSlugs.keywords,
};

const requiredColumnSlugs = [
  columnSlugs.fullName,
  columnSlugs.category,
  columnSlugs.page_url,
];

const parsersMap = {
  [columnSlugs.fullName]: (value: string) => value,
  [columnSlugs.category]: (value: string) => value,
  [columnSlugs.page_url]: (value: string) => value,
};

/** If the raw data of the specified column is valid, convert the raw data into useful value that can be used in the app logic */
function parseColumnValue(columnName: string, columnValue: string) {
  const parser = parsersMap[columnName];
  return parser ? parser(columnValue) : columnValue;
}

type UpdateAccountInfoModalProps = {
  account?: UserJson.Profile;
  open: boolean;
  onClose: (refresh?: boolean) => void;
};

type FormikData = UserJson.Profile & {
  file?: File;
  autoFetch?: boolean;
};

const initialValues: FormikData = {
  id: 0,
  name: '',
  page_url: '',
  instagram_username: '',
  twitter_username: '',
  aliases: '',
  category: '',
  custom_search_keywords: '',
  file: undefined,
  autoFetch: false,
};
const UpdateAccountInfoModal: React.FC<UpdateAccountInfoModalProps> = ({
  account,
  open,
  onClose,
}) => {
  const [isInvalidFile, setIsInvalidFile] = useState('');
  const [parsedFile, setParsedFile] = useState('');
  const { isMobile } = useBreakpoint();
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string[]>([]);
  const [accounts, setAccounts] = useState<FormikData[]>([
    { ...initialValues },
  ]);
  async function handleSubmit() {
    setStatus([]);
    setSubmitting(true);
    const errors: string[] = [];
    let i = 0;
    for (const acc of accounts) {
      i++;
      try {
        const formData = new FormData();
        formData.append('name', acc.name || '');
        formData.append('category', acc.category || 'Creator');
        formData.append('page_url', acc.page_url || '');
        formData.append('aliases', acc.aliases || '');
        formData.append('instagram_username', acc.instagram_username || '');
        formData.append('twitter_username', acc.twitter_username || '');
        formData.append(
          'auto_fetch_image_from_onlyfans',
          acc.autoFetch ? 'true' : 'false',
        );

        formData.append(
          'custom_search_keywords',
          acc.custom_search_keywords || '',
        );
        if (acc.file) {
          formData.append('picture', acc.file, acc.file.name.slice(-99));
        }
        if (account?.id) {
          formData.append('id', `${account.id}`);
          await axiosInstance.put('/api/profile/update', formData);
        } else {
          await axiosInstance.post('/api/profile/add', formData);
        }
      } catch (err: any) {
        const errMsg = err.response?.data?.message;
        errors.push(`Failed to update account(${i}): ${errMsg}`);
      }
    }
    if (errors.length === 0) {
      onClose(true);
      resetForm();
    } else {
      setStatus(errors);
    }
    setSubmitting(false);
  }

  useEffect(() => {
    if (account) {
      setAccounts([
        {
          id: account.id,
          name: account.name || '',
          picture: account.picture || '',
          page_url: account.page_url || '',
          category: account.category || '',
          instagram_username: account.instagram_username || '',
          twitter_username: account.twitter_username || '',
          aliases: account.aliases || '',
          custom_search_keywords: account.custom_search_keywords || '',
          file: undefined,
        },
      ]);
    } else {
      setAccounts([{ ...initialValues }]);
    }
    setStatus([]);
  }, [account]);
  function resetForm() {
    setStatus([]);
    setAccounts([{ ...initialValues }]);
  }

  function handleUpdate(id: number, data: Partial<FormikData>) {
    setAccounts(
      [...accounts].map((acc, index) =>
        id === index
          ? {
              ...acc,
              ...data,
            }
          : acc,
      ),
    );
  }
  async function handleChangeFile(file: File) {
    setIsInvalidFile('');
    setParsedFile('');
    if (file) {
      const parsedData = await fileHelper.parseExcelFile(
        file,
        parseColumnValue,
        columnNamesMap,
        requiredColumnSlugs,
      );
      if (parsedData.isAcceptable) {
        const profiles = parsedData.results;
        if (profiles.length === 0) {
          setIsInvalidFile('There is not any profile data');
        } else {
          setAccounts([...accounts, ...profiles]);
          setParsedFile(`${profiles.length} profiles were imported.`);
        }
      } else {
        setIsInvalidFile(
          'Invalid file format. Please download template and use it',
        );
      }
    }
  }
  return (
    <Modal show={open} onHide={() => onClose(false)} size="lg">
      <Modal.Header className="px-3 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">
          {account ? 'Update Profile' : 'Add a Profile or Mass Upload'}
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="py-4 py-md-5 pt-0 pt-md-0">
        <div
          className="overflow-auto px-0 px-md-5 mb-3"
          style={{ maxHeight: 'calc(100vh - 270px)' }}
        >
          {!account?.id && (
            <div className="mb-4">
              <FileDropzone
                onDrop={(files) =>
                  files.length > 0 && handleChangeFile(files[0])
                }
              />
              <p className="text-muted fs-xs my-2">
                Please upload your Excel file in the{' '}
                <a
                  href="/assets/templates/profile-template.xlsx"
                  download="profile.template.xlsx"
                  className="text-primary text-decoration-underline"
                >
                  following format:
                </a>{' '}
                Each row should include the fields "Full name", "Creator or
                Celebrity", and "Exclusive Content URL" as mandatory fields.
                Optional fields "Also Known As", "Instagram @", "Twitter @", and
                "Keywords" should also be included, even if left blank. Ensure
                all values are separated by commas and follow this structure
              </p>
              {isInvalidFile && (
                <Alert className="py-2" variant="warning">
                  {isInvalidFile}
                </Alert>
              )}
              {parsedFile && (
                <Alert className="py-2" variant="success">
                  {parsedFile}
                </Alert>
              )}
            </div>
          )}
          {accounts.map((acc, id) => (
            <div key={id}>
              <div className="d-flex align-items-center mb-3 mb-sm-4">
                <div className="me-4">
                  <UserAvatar
                    size={85}
                    editable
                    imgUrl={acc?.picture}
                    colorIndex={id}
                    onChangeFile={(file) => {
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          handleUpdate(id, {
                            file,
                            picture: reader.result as string,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </div>
                <div>
                  <FormCheck
                    id={`auto-fetch-${id}`}
                    checked={acc.autoFetch}
                    label="Auto fetch from OnlyFans"
                    onChange={(e) =>
                      handleUpdate(id, { autoFetch: e.target.checked })
                    }
                  />
                </div>
              </div>
              <div className="row flex-grow-1 mb-4">
                <div className="col-12 col-sm-6">
                  <FormGroup className="mb-3 mb-sm-0">
                    <FormControl
                      size={isMobile ? undefined : 'lg'}
                      placeholder="Full name"
                      value={acc.name}
                      onChange={(e) =>
                        handleUpdate(id, { name: e.target.value })
                      }
                    />
                  </FormGroup>
                </div>
                <div className="col-12 col-sm-6">
                  <FormGroup>
                    <FormSelect
                      size={isMobile ? undefined : 'lg'}
                      value={acc.category}
                      onChange={(e) =>
                        handleUpdate(id, { category: e.target.value })
                      }
                    >
                      <option value="" disabled></option>
                      <option value="Creator">Creator</option>
                      <option value="Celebrity">Celebrity</option>
                    </FormSelect>
                  </FormGroup>
                </div>
              </div>
              <FormGroup className="mb-3 mb-sm-4">
                <FormControl
                  size={isMobile ? undefined : 'lg'}
                  placeholder="Exclusive Content URL"
                  value={acc.page_url}
                  onChange={(e) =>
                    handleUpdate(id, { page_url: e.target.value })
                  }
                />
              </FormGroup>

              <div className="row mb-3 mb-sm-4">
                <div className="col">
                  <FormGroup>
                    <FormControl
                      size={isMobile ? undefined : 'lg'}
                      placeholder="Instagram @"
                      value={acc.instagram_username}
                      onChange={(e) =>
                        handleUpdate(id, { instagram_username: e.target.value })
                      }
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup>
                    <FormControl
                      size={isMobile ? undefined : 'lg'}
                      placeholder="Twitter @"
                      value={acc.twitter_username}
                      onChange={(e) =>
                        handleUpdate(id, { twitter_username: e.target.value })
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <FormGroup className="mb-3 mb-sm-4">
                <FormControl
                  as="textarea"
                  size={isMobile ? undefined : 'lg'}
                  placeholder="Also known as..."
                  value={acc.aliases}
                  onChange={(e) =>
                    handleUpdate(id, { aliases: e.target.value })
                  }
                  rows={2}
                />
              </FormGroup>

              <FormGroup className="mb-3 mb-sm-4">
                <FormControl
                  as="textarea"
                  className="mb-4"
                  size={isMobile ? undefined : 'lg'}
                  rows={2}
                  placeholder="Keywords"
                  value={acc.custom_search_keywords}
                  onChange={(e) =>
                    handleUpdate(id, {
                      custom_search_keywords: e.target.value,
                    })
                  }
                />
              </FormGroup>
              {!account && (
                <div className="d-flex align-items-center mb-3">
                  <hr className="flex-grow-1" />
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle cursor-pointer"
                    style={{
                      width: 26,
                      height: 26,
                      background: '#f7f3f3',
                      color: '#bcc0c7',
                    }}
                    onClick={() => {
                      if (id < accounts.length - 1) {
                        const newAccounts = [...accounts].filter(
                          (v, index) => index !== id,
                        );
                        setAccounts(newAccounts);
                      } else {
                        setAccounts([...accounts, { ...initialValues }]);
                      }
                    }}
                  >
                    {id < accounts.length - 1 ? '-' : '+'}
                  </div>
                  <hr className="flex-grow-1" />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="px-0 px-md-5">
          {status.length > 0 &&
            status.map((s, id) => (
              <Alert key={id} variant="danger">
                {s}
              </Alert>
            ))}
          <Button
            className="w-100"
            size={isMobile ? undefined : 'lg'}
            disabled={submitting}
            onClick={() => handleSubmit()}
          >
            {submitting && <Spinner size="sm" className="me-2" />}
            {account
              ? 'Update Account Information'
              : `Add & Confirm + $${defaultMonthly * accounts.length}/mo per profile`}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UpdateAccountInfoModal;
