import CloseIcon from '@assets/icons/CloseIcon';
import { Button, Modal, ModalTitle } from 'react-bootstrap';

type ConfirmModalProps = {
  title?: string;
  description?: string;
  open: boolean;
  onConfirm: (confirm?: boolean) => void;
};
const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title = 'Are you sure?',
  description = 'This action would not be recoverable',
  open,
  onConfirm,
}) => {
  return (
    <Modal show={open} onHide={() => onConfirm()}>
      <Modal.Header className="px-4 px-md-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold">{title}</ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={() => onConfirm()}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="p-4 p-md-5 pt-0 pt-md-0 d-flex gap-4 flex-column">
        <p>{description}</p>
        <div className="d-flex justify-content-end gap-3">
          <Button variant="outline-primary" onClick={() => onConfirm(true)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => onConfirm(true)}>
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmModal;
