import { AppLogoIcon } from '@assets/icons';
import clsx from 'clsx';
import { Card } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const AuthLayout: React.FC = () => {
  return (
    <main className="bg-white p-2" style={{ minHeight: `100vh` }}>
      <div className="container d-flex justify-content-center align-items-center flex-column gap-4 py-5">
        <AppLogoIcon />
        <Card
          className={clsx(
            'd-flex flex-column gap-4 border-0 p-md-4 rounded-xl mt-2',
          )}
          style={{ maxWidth: 500 }}
        >
          <Outlet />
        </Card>
      </div>
    </main>
  );
};

export default AuthLayout;
