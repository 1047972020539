/* eslint-disable no-throw-literal */
import { auth } from '@constants/firebase-config';
import {
  AuthError,
  EmailAuthProvider,
  GoogleAuthProvider,
  browserLocalPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from 'firebase/auth';
import AuthJson from 'types/auth.type';
const googleProvider = new GoogleAuthProvider();

class AuthService {
  async register({ email, password, ...others }: AuthJson.SignupInput) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      return userCredential;
    } catch (err) {
      throw err as AuthError;
    }
  }

  async login({ email, password, rememberMe = false }: AuthJson.SigninInput) {
    try {
      const persistence = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await setPersistence(auth, persistence);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      return userCredential;
    } catch (err) {
      throw err as AuthError;
    }
  }

  logout() {
    return signOut(auth);
  }

  async signInWithGoogle() {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      return result;
    } catch (err) {
      throw err as AuthError;
    }
  }
  async sendPasswordResetEmail(email: string) {
    try {
      const result = await sendPasswordResetEmail(auth, email);
      return result;
    } catch (err) {
      throw err as AuthError;
    }
  }
  async resetPassword({
    newPassword,
    oobCode,
  }: {
    newPassword: string;
    oobCode: string;
  }) {
    try {
      const result = await confirmPasswordReset(auth, oobCode, newPassword);
      return result;
    } catch (err) {
      throw err as AuthError;
    }
  }

  async changePassword(curPassword: string, newPassword: string) {
    try {
      const user = auth.currentUser;
      if (!user?.email) {
        throw new Error('You need to signin');
      }
      const credentials = EmailAuthProvider.credential(user.email, curPassword);
      await reauthenticateWithCredential(user, credentials);
      const result = await updatePassword(user, newPassword);
      return result;
    } catch (err) {
      throw err as AuthError;
    }
  }
}

const authService = new AuthService();
export default authService;
