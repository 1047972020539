import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  FormText,
  Row,
  Spinner,
  ToggleButton,
} from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import clsx from 'clsx';
import * as yup from 'yup';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import axiosInstance from '@utils/axios-service';
import useAuth from '@hooks/useAuth';
import {
  annualSaveRate,
  defaultAnnually,
  defaultMonthly,
} from '@constants/prices.app';
export const cardElementOptions = {
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Inter", sans-serif',
      fontSmoothing: 'antialiased',
      padding: '6px 12px',
      '::placeholder': {
        color: '#809fb8',
        RiFontSize: '16px',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};
export type BillingCardInfo = {
  name: string;
  country: string;
  postal_code: string;
  agree: boolean;
  promo_code: string;
};
export const billingInitialValues: BillingCardInfo = {
  name: '',
  country: 'US',
  postal_code: '',
  agree: true,
  promo_code: '',
};
export const billingValidationSchema = yup.object({
  name: yup.string().required('Cardholder name is required'),
  country: yup
    .string()
    .required('Country is required')
    .length(2, 'Country name should be 2 characters'),
  postal_code: yup
    .string()
    .required('ZIP is required')
    .min(5, 'ZIP must be at least 5 characters long')
    .max(7, 'ZIP must be at most 7 characters long'),
  agree: yup.boolean().isTrue('Agree is needed'),
});
const Checkout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isAgency = searchParams.get('userType') === 'agency';
  const {
    refetchUser,
    refetchProfiles,
    paymentDetails,
    refetchPaymentDetails,
  } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [plan, setPlan] = useState<'monthly' | 'yearly'>('monthly');
  const navigate = useNavigate();
  const price = plan === 'monthly' ? defaultMonthly : defaultAnnually;
  const formik = useFormik<BillingCardInfo>({
    initialValues: billingInitialValues,
    validationSchema: billingValidationSchema,
    onSubmit: async (
      { name, country, postal_code, promo_code },
      { setStatus },
    ) => {
      setStatus('');
      if (!stripe || !elements) {
        setStatus('Strip elements is required');
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);
      if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
        setStatus('Strip elements is required');
        return;
      }

      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name,
            address: {
              country,
              postal_code,
            },
          },
        });
        if (error) {
          setStatus('Could not create payment method');
          return;
        }
        await axiosInstance.put(`/api/profile/account/update`, {
          payment_plan: plan,
          payment_method_id: paymentMethod.id,
        });

        await axiosInstance.post(`/api/payment/start-trial/`, {
          next_plan_interval: plan,
          ...(promo_code ? { promo_code } : {}),
        });
        await Promise.all([
          refetchUser(),
          refetchProfiles(),
          refetchPaymentDetails(),
        ]);
        navigate(isAgency ? appRoutes.accounts : appRoutes.dashboard);
      } catch (err: any) {
        setStatus(
          err.response?.data?.message || 'Could not subscribe payment.',
        );
      }
    },
  });

  useEffect(() => {
    if (paymentDetails?.payment_method_details) {
      navigate(appRoutes.dashboard);
    }
  }, [paymentDetails]);

  return (
    <>
      <h2 className="fs-xl">Start my Free Trial</h2>
      <ButtonGroup className="rounded-pill" style={{ background: '#E5E7EE' }}>
        <ToggleButton
          id="yearly"
          value="yearly"
          checked={plan === 'yearly'}
          variant={plan === 'yearly' ? 'outline-primary' : 'transparent'}
          onClick={() => setPlan('yearly')}
          className={clsx('w-50 rounded-pill', plan === 'yearly' && 'bg-white')}
        >
          <span style={{ color: plan !== 'yearly' ? '#A7A9B0' : undefined }}>
            Annually
          </span>{' '}
          <span className="fs-xs text-success">Save {annualSaveRate}%</span>
        </ToggleButton>
        <ToggleButton
          id="monthly"
          value="monthly"
          variant={plan === 'monthly' ? 'outline-primary' : 'transparent'}
          onClick={() => setPlan('monthly')}
          className={clsx(
            'w-50 rounded-pill',
            plan === 'monthly' && 'bg-white',
          )}
        >
          <span style={{ color: plan !== 'monthly' ? '#A7A9B0' : undefined }}>
            Monthly
          </span>
        </ToggleButton>
      </ButtonGroup>
      <div className="mb-2">
        <h4 className="fs-lg fw-semibold">Summary</h4>
        <div className="d-flex justify-content-between">
          <span>7 day free trial</span>
          <div>
            ${price} per {plan === 'monthly' ? 'month' : 'year'}
          </div>
        </div>
        <hr className="my-2" />
        <div className="d-flex justify-content-between">
          <span>Subtotal</span>
          <div>
            $0
            <span className="d-inline-flex ms-2 text-decoration-through">
              ${price}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-end">+ Applicable Tax</div>
      </div>
      {formik.status && <Alert variant="danger">{formik.status}</Alert>}

      <FormGroup>
        <FormControl
          placeholder="Cardholder name"
          {...formik.getFieldProps('name')}
          isInvalid={formik.touched.name && !!formik.errors.name}
        />
        {formik.touched.name && !!formik.errors.name && (
          <FormText className="text-danger">{formik.errors.name}</FormText>
        )}
      </FormGroup>
      <CardNumberElement
        className="form-control py-2"
        options={cardElementOptions}
      />

      <Row>
        <Col xs={6} sm={3} className="pe-sm-1 mb-4">
          <CardCvcElement
            className="form-control py-2"
            options={cardElementOptions}
          />
        </Col>
        <Col xs={6} sm={3} className="px-sm-1">
          <CardExpiryElement
            className="form-control py-2"
            options={cardElementOptions}
          />
        </Col>
        <Col xs={6} sm={3} className="px-sm-1">
          <FormGroup>
            <FormSelect
              {...formik.getFieldProps('country')}
              isInvalid={formik.touched.country && !!formik.errors.country}
            >
              <option value={'US'}>US</option>
              <option value={'CA'}>CA</option>
            </FormSelect>
            {formik.touched.country && !!formik.errors.country && (
              <FormText className="text-danger">
                {formik.errors.country}
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col xs={6} sm={3} className="ps-sm-1">
          <FormGroup>
            <FormControl
              placeholder="ZIP"
              {...formik.getFieldProps('postal_code')}
              isInvalid={
                formik.touched.postal_code && !!formik.errors.postal_code
              }
            />
            {formik.touched.postal_code && !!formik.errors.postal_code && (
              <FormText className="text-danger">
                {formik.errors.postal_code}
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <div className="d-flex justify-content-end align-items-center gap-3">
        <FormLabel className="text-nowrap">Promo Code: (optional)</FormLabel>
        <FormControl
          placeholder="Promo Code"
          {...formik.getFieldProps('promo_code')}
        />
      </div>
      <div className="d-flex justify-content-end gap-4">
        <Button variant="secondary" onClick={() => navigate(appRoutes.billing)}>
          Cancel
        </Button>
        <Button
          className=""
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting && <Spinner size="sm" className="me-3" />}
          Start Trial
        </Button>
      </div>
      <FormGroup>
        <div className="d-flex gap-3">
          <FormCheckInput
            id="agreeTerm"
            checked={formik.values.agree}
            onChange={(e) => formik.setFieldValue('agree', e.target.checked)}
            isInvalid={formik.touched.agree && !!formik.errors.agree}
          />
          <FormLabel
            className="text-muted fs-sm cursor-pointer"
            htmlFor="agreeTerm"
          >
            By placing your order - You agree to our{' '}
            <Link to={appRoutes.terms} className="text-primary">
              terms & conditions
            </Link>{' '}
            &{' '}
            <Link to={appRoutes.privacy} className="text-primary">
              privacy policy
            </Link>
            .
          </FormLabel>
        </div>

        {formik.touched.agree && !!formik.errors.agree && (
          <FormText className="text-danger">{formik.errors.agree}</FormText>
        )}
      </FormGroup>
    </>
  );
};

export default Checkout;
