import React, { useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Spinner,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import authService from '@utils/auth-service';

type FormikData = {
  email: string;
};
const initialValues: FormikData = {
  email: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
});
const ForgotPassword: React.FC = () => {
  const [sent, setSent] = useState(false);
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus('');
      setSubmitting(true);
      try {
        await authService.sendPasswordResetEmail(values.email);
        setSent(true);
      } catch (error: any) {
        setStatus(error.message);
      }
      setSubmitting(false);
    },
  });

  return (
    <>
      <h2 className="fs-xl">Forgot Password</h2>
      {formik.status && <Alert variant="danger">{formik.status}</Alert>}
      {sent && (
        <Alert variant="danger">
          Sent password reset request. Please check your email inbox
        </Alert>
      )}
      <FormGroup style={{ minWidth: 300 }}>
        <FormLabel>Email</FormLabel>
        <FormControl
          placeholder="Email address"
          {...formik.getFieldProps('email')}
          isInvalid={formik.touched.email && !!formik.errors.email}
        />
        {formik.touched.email && !!formik.errors.email && (
          <FormText className="text-danger">{formik.errors.email}</FormText>
        )}
      </FormGroup>
      <Button
        className=""
        onClick={() => formik.handleSubmit()}
        disabled={formik.isSubmitting}
      >
        {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
        Send
      </Button>
    </>
  );
};

export default ForgotPassword;
