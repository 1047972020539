import { useRef } from 'react';

const bgColors = [
  '#c3ddfd',
  '#eceaea',
  '#a7d7ad',
  '#9eeaf9',
  '#ffe69c',
  '#ffc1ce',
  '#e9ecef',
];
type UserAvatarProps = {
  size?: number;
  imgUrl?: string;
  editable?: boolean;
  colorIndex?: number;
  onChangeFile?: (file?: File) => void;
};
const UserAvatar: React.FC<UserAvatarProps> = ({
  size = 74,
  imgUrl,
  editable,
  colorIndex = 0,
  onChangeFile,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
        background: bgColors[colorIndex % bgColors.length],
        borderRadius: size,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={imgUrl || '/assets/images/default-avatar.png'}
        style={{
          width: size,
          height: size,
          minWidth: size,
          borderRadius: size,
        }}
        alt=""
      />
      {editable && (
        <div
          style={{
            width: 26,
            height: 26,
            minWidth: 26,
            background: '#b2d7fe',
            color: 'white',
            borderRadius: 26,
            position: 'absolute',
            bottom: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          role="button"
          onClick={() => fileRef.current?.click()}
        >
          +
        </div>
      )}
      <input
        ref={fileRef}
        type="file"
        hidden
        accept="image/*"
        onChange={(e) => onChangeFile?.(e.target.files?.[0])}
      />
    </div>
  );
};

export default UserAvatar;
