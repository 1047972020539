import { auth } from '@constants/firebase-config';
import appRoutes, { protectedRoutes } from '@constants/routes.app';
import axiosInstance from '@utils/axios-service';
import { saveAccessToken } from '@utils/local-storage';
import { onAuthStateChanged } from 'firebase/auth';
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthJson from 'types/auth.type';
import StripeJson from 'types/stripe.type';
import UserJson from 'types/user.type';

type AuthContextType = {
  authUser: AuthJson.Auth | null;
  user: UserJson.Account | null;
  profiles: UserJson.Profile[];
  loading: boolean;
  loadingUser: boolean;
  loadingProfiles: boolean;
  paymentDetails: StripeJson.PaymentDetails | null;
  refetchUser: () => void;
  refetchProfiles: () => void;
  refetchPaymentDetails: () => void;
};
const initialValue: AuthContextType = {
  authUser: null,
  user: null,
  profiles: [],
  loading: false,
  loadingUser: false,
  loadingProfiles: false,
  paymentDetails: null,
  refetchUser: () => null,
  refetchProfiles: () => null,
  refetchPaymentDetails: () => null,
};
export const AuthContext = createContext<AuthContextType>(initialValue);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authUser, setAuthUser] = useState<AuthJson.Auth | null>(null);
  const [user, setUser] = useState<UserJson.Account | null>(null);
  const [profiles, setProfiles] = useState<UserJson.Profile[]>([]);
  const [paymentDetails, setPaymentDetails] =
    useState<StripeJson.PaymentDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const refetchUser = async () => {
    setLoadingUser(true);
    try {
      const { data } = await axiosInstance.get('/api/profile/account/details');
      const currentUser: UserJson.Account = data.data || null;
      setUser(currentUser);
    } catch (err) {}
    setLoadingUser(false);
  };
  async function refetchPaymentDetails(refresh = false) {
    if (!refresh) setLoading(true);
    try {
      const { data } = await axiosInstance.get('/api/payment/details');
      setPaymentDetails(data.data || null);
    } catch (err) {
      setPaymentDetails(null);
    }
    setLoading(false);
  }
  const refetchProfiles = async () => {
    setLoadingProfiles(true);
    try {
      const { data } = await axiosInstance.get('/api/profile/all');
      const currentProfiles: UserJson.Profile[] = data.data.profiles || [];
      setProfiles(currentProfiles);
    } catch (err) {}
    setLoadingProfiles(false);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const currentUser = user as AuthJson.Auth | null;
      setAuthUser(currentUser);
      saveAccessToken(currentUser?.accessToken);
      if (currentUser) {
        refetchUser();
        refetchProfiles();
        refetchPaymentDetails();
      } else {
        setUser(null);
        setProfiles([]);
        setPaymentDetails(null);
        if (protectedRoutes.includes(location.pathname)) {
          navigate(appRoutes.signin);
        }
      }
      setLoading(false);
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authUser,
        loading,
        loadingUser,
        loadingProfiles,
        profiles,
        paymentDetails,
        refetchUser,
        refetchProfiles,
        refetchPaymentDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
