import CloseIcon from '@assets/icons/CloseIcon';
import appConfig from '@constants/appConfig.app';
import useAuth from '@hooks/useAuth';
import useBreakpoint from '@hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import { Button, Modal, ModalTitle } from 'react-bootstrap';

const CongratulationsModal: React.FC = () => {
  const { authUser: currentUser, loading } = useAuth();
  const key = `first-visit-${currentUser?.uid}`;
  const [open, setOpen] = useState(false);
  const { isMobile } = useBreakpoint();
  function onClose() {
    localStorage.setItem(key, 'true');
    setOpen(false);
  }
  useEffect(() => {
    if (!loading && localStorage.getItem(key) !== 'true') {
      setOpen(true);
    }
  }, [key, loading]);
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header className="px-5 py-4 border-0 position-relative">
        <ModalTitle className="mt-3 fs-4 fw-semibold d-flex align-items-center gap-2 me-5">
          Congratulations! You’re all good to go! 🎉
        </ModalTitle>
        <div
          className="position-absolute cursor-pointer"
          style={{ top: 20, right: 20 }}
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      </Modal.Header>
      <Modal.Body className="p-5 pt-0 d-flex flex-column gap-2 fs-sm">
        <p>Welcome aboard! Here’s what’s next:</p>
        <p>
          <strong>1. Activation:</strong> Your account is active,{' '}
          <strong>{appConfig.APP_NAME}</strong> is already connected to your
          digital assets, and warming up.
        </p>
        <p>
          <strong>2. Monitoring:</strong> We scan the web for copyright
          violations 24/7 from now on.
        </p>
        <p>
          <strong>3. Action:</strong> We take down infringing content
          automatically.
        </p>
        <p>
          <strong>4. Reporting:</strong> Stay updated on takedown requests.
        </p>
        <p>
          <strong>5. Continuous Improvement:</strong> We’re always improving.
        </p>
        <p>Thanks for choosing us. We’re always here to support you!</p>
        <Button
          className="w-100 mt-4"
          size={isMobile ? undefined : 'lg'}
          onClick={onClose}
        >
          Get Started
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default CongratulationsModal;
