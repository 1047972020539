import ArrowDownIcon from '@assets/icons/ArrowDownIcon';
import MinusIcon from '@assets/icons/MinusIcon';
import GetStartedCard from '@components/GetStartedCard';
import PlatformCard from '@components/PlatformCard';
import {
  CREATOR_IMAGE,
  PLATFORM_CARD,
  GET_STARTED_CARD,
  GET_STARTED_CARD_MOBILE,
} from '@constants/homePage';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Image,
  Row,
  Stack,
} from 'react-bootstrap';
import appRoutes from '@constants/routes.app';

import { Link } from 'react-router-dom';
import faqs from '@mock/faq.mock';
import { defaultMonthly } from '@constants/prices.app';
import appConfig from '@constants/appConfig.app';
import useBreakpoint from '@hooks/useBreakpoint';

const Home: React.FC = () => {
  const { isMobile } = useBreakpoint();
  const IMAGES = [...CREATOR_IMAGE, ...CREATOR_IMAGE];
  const [openId, setOpenId] = useState(0);
  return (
    <>
      <section
        className="bg_image bg_home hero_section font-family-sans-serif d-flex justify-content-center  align-items-center pt-5"
        // style={{ height: '600px' }}
      >
        <Container className="mt-2">
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg={6}>
              <Stack gap={4} className="inner_stack">
                <div>
                  <h1 className="m-0">The ultimate DMCA</h1>
                  <h1 className="m-0">
                    software for{' '}
                    <span className="text-span fst-italic">creators</span>
                  </h1>
                </div>
                <div className="text-left p_div fw-semibold">
                  <p>
                    Say hello to peace of mind. Our platform empowers creators
                    like you to protect your intellectual property effortlessly
                  </p>
                </div>
                <Link to={appRoutes.signup}>
                  <Button className="p-3 btn-bg text-white rounded-3 btn">
                    Get Started Free
                  </Button>
                </Link>
                <p className="fw-semibold">
                  7-day free trial, then just ${defaultMonthly}/mo
                </p>
                <div
                  className="google-none d-flex justify-content-start  align-items-center gap-2 border-2 border border-secondary-subtle rounded-pill p-2 glass-background"
                  style={{ width: 'fit-content' }}
                >
                  <Image
                    src="/assets/images/google.png"
                    width={22}
                    height={22}
                  />
                  <div className="mr-2" style={{ fontSize: '12px' }}>
                    <p className="gp">Trusted Google Partner</p>
                    <p className="cp">Copyright Removal Program</p>
                  </div>
                </div>
              </Stack>
            </Col>
            <Col lg={6} className="hero_img">
              <Image
                src="/assets/images/hero_image.png"
                width={'80%'}
                height={'100%'}
              />
            </Col>
            <Col className="google-block">
              <div className=" d-flex justify-content-center  align-items-center gap-3 border-2 border border-secondary-subtle rounded-pill w-77 py-2 px-2 glass-background ">
                <Image src="/assets/images/google.png" width={22} height={22} />
                <div className="mr-2" style={{ fontSize: '12px' }}>
                  <p className="">Trusted Google Partner</p>
                  <p>Copyright Removal Program</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="pt-5 pb-2 my-2 mb-2 font-family-sans-serif creator_section"
        style={{ overflowX: 'hidden' }}
      >
        <Container fluid={'xxl'}>
          <Row>
            <Col
              lg={12}
              className="d-flex flex-column  justify-content-center align-items-center m-auto mb-4 text-center "
            >
              <div className="creator_h2 fw-regular lh-sm ">
                <h2 className="m-0">Creators lose $130m/day</h2>
                <h2 className="m-0">due to copyright infringement...</h2>
                <h2 className="m-0">We‘re bridging that gap.</h2>
              </div>
            </Col>
            <Col lg={12} className="my-2">
              <Stack
                direction="horizontal"
                gap={5}
                className="scrollable-images animated-stack"
              >
                {IMAGES.map((item, id) => (
                  <Image
                    className="mx-3"
                    key={id}
                    src={item.img}
                    alt=""
                    width={item.width}
                    height={item.height}
                  />
                ))}
              </Stack>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-2 mb-2 mt-2 platform_section">
        <Container>
          <Row>
            <Col lg={8} className="m-auto text-center pb-5 pt-4 fw-regular">
              <h2 className="m-0">The only platform built for scale</h2>
            </Col>
          </Row>
          <Row>
            {PLATFORM_CARD.map((item, id) => (
              <Col lg={4} key={id} className="mb-3">
                <PlatformCard
                  title={item.title}
                  subTitle={item.subTitle}
                  image={item.image}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="py-2 mt-5 mb-5 font-family-sans-serif instant_section">
        <Container>
          <Row className="justify-content-center align-items-center row_inner">
            <Col
              lg={7}
              className="col_inner"
              style={{
                backgroundImage: 'url(/assets/images/insight.png)',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                minHeight: isMobile ? 200 : 300,
              }}
            ></Col>
            <Col lg={5}>
              <Stack direction="vertical" gap={3} className="instant_col">
                <div className="">
                  <h2 className="m-0 fw-semibold">Instant insights,</h2>
                  <h2 className="m-0 fw-semibold">powerful impact</h2>
                </div>
                <p className="lh-2 fw-semibold">
                  Say hello to peace of mind. Our platform empowers creators
                  like you to protect your itellectual property effortlessly.
                </p>
                <Link
                  to={appRoutes.signup}
                  className="text-decoration-none w-50 text-left text-primary"
                >
                  Get Started Free
                </Link>
              </Stack>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="top_creators py-2 my-2 font-family-sans-serif">
        <Container>
          <Row
            className="justify-content-between align-items-center shadow-sm rounded-3 p-3 inner_row top_creators_bg"
            // style={{ backgroundColor: '#D4E6FC' }}
          >
            <Col lg={5} className="">
              <div className="lh-1 heading mb-2">
                <h2 className="m-0 fs-3">
                  68% of{' '}
                  <span className="text-span fst-italic">top creators</span>{' '}
                  scale
                </h2>
                <h2 className="m-0 fs-3">
                  {' '}
                  their success with {appConfig.APP_NAME}
                </h2>
              </div>
            </Col>
            <Col lg={5}>
              <div className="d-flex justify-content-between gap-2 avator flex-wrap">
                <div className="d-flex flex-column justify-content-center align-items-center  gap-2">
                  <Image
                    src="/assets/images/alex_kay.png"
                    width={65}
                    height={65}
                  />
                  <p className="name-span">@alexkay</p>
                  <div
                    className="badge text-white bg-primary shadow-sm py-2 opacity-75"
                    style={{ fontSize: '12px' }}
                  >
                    139k Leaks Delisted
                  </div>
                </div>
                <div className="d-flex flex-column  justify-content-center align-items-center gap-2">
                  <Image
                    src="/assets/images/autun.png"
                    width={65}
                    height={65}
                  />
                  <p className="name-span">@autumn_renxo</p>
                  <div
                    className="badge text-white bg-primary shadow-sm py-2 opacity-75"
                    style={{ fontSize: '12px' }}
                  >
                    129k Leaks Delisted
                  </div>
                </div>
                <div className="d-flex flex-column  justify-content-center align-items-center gap-2">
                  <Image
                    src="/assets/images/DAISY_PARKER.png"
                    width={65}
                    height={65}
                  />
                  <p className="name-span">@imdaisyparker</p>
                  <div
                    className="badge text-white bg-primary shadow-sm py-2 opacity-75"
                    style={{ fontSize: '12px' }}
                  >
                    19k Leaks Delisted
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-4 pb-2 my-2 get_started_section">
        <Container>
          <Row>
            <Col lg={12} className="m-auto text-left pb-4 fw-regular">
              <h2 className="m-0 p-0">Get started easily</h2>
            </Col>
          </Row>
          <Row className="desktop_img">
            {GET_STARTED_CARD.map((item, id) => (
              <Col lg={4} key={id}>
                <GetStartedCard
                  image={item.image}
                  description={item.description}
                />
              </Col>
            ))}
          </Row>
          <Row className="mobile_img">
            {GET_STARTED_CARD_MOBILE.map((item, id) => (
              <Col lg={4} key={id}>
                <GetStartedCard
                  image={item.image}
                  description={item.description}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="faq_section pb-4">
        <Container>
          <Row>
            <Col lg={8} className="m-auto text-center pb-5 pt-4 fw-regular">
              <h2 className="m-0">FAQ</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="d-flex flex-column gap-3">
                {faqs.map((faq, id) => (
                  <Card
                    className="bg-white p-4 cursor-pointer rounded-3"
                    onClick={() => setOpenId(id)}
                    key={id}
                  >
                    <div className="d-flex justify-content-between gap-5 mb-2">
                      <p className="fs-lg fw-semibold">{faq.question}</p>
                      <div>
                        {openId === id ? <MinusIcon /> : <ArrowDownIcon />}
                      </div>
                    </div>

                    <Collapse in={openId === id}>
                      <div>{faq.answer}</div>
                    </Collapse>
                  </Card>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-2 mt-2">
        <Container>
          <Row>
            <Col lg={6} className="m-auto text-center py-4 fw-semibold">
              <h2>The only platform that can support your content at scale </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="m-auto text-center">
              <Link to={appRoutes.signup}>
                <Button className="p-3 btn-bg col-lg-4 col-sm-12 text-white rounded-3 mb-3">
                  Get Started Free
                </Button>
              </Link>
              <p className="fw-semibold py-2">
                7-day free trial, then just ${defaultMonthly}/mo
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Image
                src="/assets/images/dashboard.png"
                width={'100%'}
                height={'100%'}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
