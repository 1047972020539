import AppTypes from '@models/app.type';

const Graph2Icon: React.FC<AppTypes.Icon & { value?: number }> = ({
  width = 101,
  height = 28,
  color = 'rgb(106, 170, 249)',
  value,
}) => {
  return (
    <div className="position-relative d-flex gap-1" style={{ width, height }}>
      {[0, 1, 2, 3].map((v) => (
        <div
          key={v}
          style={{
            background: '#f9f5f5',
            flexGrow: 1,
            height: '100%',
          }}
        />
      ))}
      {value && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          className="position-absolute top-0 left-0 right-0 bottom-0"
          viewBox="0 0 101 28"
        >
          <path
            fillRule="evenodd"
            stroke={color}
            strokeWidth="2px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            fill="none"
            d="M1.1000,1.750 C20.449,18.454 37.865,27.468 52.000,22.750 C57.237,21.002 60.041,17.929 65.000,15.750 C74.282,11.671 85.256,11.372 98.000,14.750 "
          />
        </svg>
      )}
    </div>
  );
};

export default Graph2Icon;
