import ConfirmModal from '@components/modals/ConfirmModal';
import UpdateProfileModal from '@components/modals/UpdateProfileModal';
import UpdateWhitelistDomainModal from '@components/modals/UpdateWhitelistDomainModal';
import useBreakpoint from '@hooks/useBreakpoint';
import axiosInstance from '@utils/axios-service';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, FormCheck, Spinner, Table } from 'react-bootstrap';
import UserJson from 'types/user.type';

const WhitelistedDomains: React.FC = () => {
  const { isMobile } = useBreakpoint();
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [domains, setDomains] = useState<UserJson.Domain[]>([]);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedDomainIds, setSelectedDomainIds] = useState<number[]>([]);
  const [domain, setDomain] = useState<UserJson.Domain | undefined>(undefined);

  const size = isMobile ? 5 : 20;
  const refetchDomains = async () => {
    setLoadingProfiles(true);
    try {
      const { data } = await axiosInstance.get(
        `/api/profile/whitelist-domain/list?page=${page}&page_size=${size}`,
      );
      setDomains(data.data.domains || []);
      setTotal(data.data.total_domains || 0);
    } catch (err) {}
    setLoadingProfiles(false);
  };
  const totalPage = useMemo(() => Math.floor((total - 1) / size) + 1, [total]);
  useEffect(() => {
    refetchDomains();
  }, [size, page]);

  const [deleting, setDeleting] = useState(false);
  async function handleRemove() {
    setDeleting(true);
    try {
      for (const id of selectedDomainIds) {
        await axiosInstance.delete(
          `/api/profile/whitelist-domain/delete?id=${id}`,
        );
      }
      await Promise.all([refetchDomains()]);
      setSelectedDomainIds([]);
    } catch (err) {}
    setDeleting(false);
    refetchDomains();
  }
  return (
    <div>
      <div className="mb-2 mb-md-4">
        <h1 className="fs-xxl mb-0">Whitelisted Domains</h1>
        <p className="text-muted fs-sm">
          Here you can add domains you wish to be ignored, such as affiliates,
          socials, partners, & official sources
        </p>
      </div>

      <div className="d-flex gap-4 mb-4">
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => {
            setDomain(undefined);
            setOpen(true);
          }}
        >
          Add To Whitelist
        </Button>
        {!isMobile && (
          <Button
            size="sm"
            variant="outline-danger"
            disabled={!selectedDomainIds.length || deleting}
            onClick={() => setShowConfirmModal(true)}
          >
            {deleting && <Spinner size="sm" className="me-2" />}
            Remove From Whitelist
          </Button>
        )}
      </div>
      {loadingProfiles && (
        <div className="p-5 d-flex justify-content-center">
          <Spinner />
        </div>
      )}

      {!loadingProfiles && domains.length === 0 ? (
        <div className="p-5 d-flex justify-content-center">
          Add your whitelisted domains here
        </div>
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between mb-4">
            <div>
              Showing {domains.length} - {page} of {totalPage}
            </div>
            <div className="d-flex gap-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setPage(page - 1)}
                disabled={page <= 1}
              >
                Previous
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setPage(page + 1)}
                disabled={page >= totalPage}
              >
                Next
              </Button>
            </div>
          </div>
          {!isMobile && (
            <Table className="bg-transparent" responsive>
              <tbody>
                {!loadingProfiles &&
                  domains.map((d, id) => (
                    <tr key={id}>
                      <td className="bg-transparent" valign="middle" width={20}>
                        <FormCheck
                          checked={selectedDomainIds.includes(d.id || 0)}
                          onChange={(e) => {
                            let newSelection = [...selectedDomainIds];
                            if (e.target.checked) {
                              newSelection.push(d.id || 0);
                            } else {
                              newSelection = newSelection.filter(
                                (it) => it !== d.id,
                              );
                            }
                            setSelectedDomainIds(newSelection);
                          }}
                        />
                      </td>
                      <td className="bg-transparent" valign="middle">
                        {d.domain_url}
                      </td>
                      <td
                        className="bg-transparent"
                        valign="middle"
                        align="right"
                      >
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={() => {
                            setOpen(true);
                            setDomain(d);
                          }}
                        >
                          Update
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {isMobile && (
            <div className="d-flex flex-column gap-2">
              {!loadingProfiles &&
                domains.map((dm, id) => (
                  <Card key={id} className="py-3 px-3 border-0 shadow-sm">
                    <p>{dm.domain_url}</p>
                    <div className="d-flex gap-3">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => {
                          setOpen(true);
                          setDomain(dm);
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => {
                          setSelectedDomainIds([dm.id]);
                          setShowConfirmModal(true);
                        }}
                        disabled={!deleting && dm.id === selectedDomainIds[0]}
                      >
                        Remove
                      </Button>
                    </div>
                  </Card>
                ))}
            </div>
          )}
        </>
      )}

      <UpdateWhitelistDomainModal
        open={open}
        onClose={(refresh) => {
          if (refresh) {
            refetchDomains();
          }
          setOpen(false);
        }}
        domain={domain}
      />
      <ConfirmModal
        open={showConfirmModal}
        onConfirm={(confirm) => {
          setShowConfirmModal(false);
          if (confirm) handleRemove();
        }}
      />
      <UpdateProfileModal
        open={showProfileEditModal}
        onClose={() => setShowProfileEditModal(false)}
      />
    </div>
  );
};

export default WhitelistedDomains;
