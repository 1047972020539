import ConfirmModal from '@components/modals/ConfirmModal';
import SwitchCycleConfirmModal from '@components/modals/SwitchCycleConfirmModal';
import UpdateBillingModal from '@components/modals/UpdateBillingModal';
import UpgradePlanModal from '@components/modals/UpgradePlanModal';
import appRoutes from '@constants/routes.app';
import useAuth from '@hooks/useAuth';
import axiosInstance from '@utils/axios-service';
import moment from 'moment';
import { useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const confirmContents: Record<string, { title: string; description: string }> =
  {
    'cancel-plan': {
      title: 'Are you sure?',
      description:
        'Do you want to cancel the plan? Your current plan will be canceled in the next payment date.',
    },
  };
const Billing: React.FC = () => {
  const [errorStatus, setErrorStatus] = useState('');
  const { profiles, paymentDetails, refetchPaymentDetails } = useAuth();
  const [confirmType, setConfirmType] = useState<null | 'cancel-plan'>(null);
  const [cancelingPlan, setCancelingPlan] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [showSwitchCycleConfirmModal, setShowSwitchCycleConfirmModal] =
    useState(false);
  const [switchingBillingCycle, setSwitchingBillingCycle] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const isMonthly = (paymentDetails?.interval || 'month') === 'month';
  async function switchBillingCycle() {
    setSwitchingBillingCycle(true);
    setErrorStatus('');
    try {
      await axiosInstance.post('/api/payment/change-plan/', {
        next_plan_interval: isMonthly ? 'year' : 'month',
      });
      refetchPaymentDetails();
    } catch (err: any) {
      setErrorStatus(
        err.response?.data?.message || 'Failed to change billing cycle',
      );
    }
    setSwitchingBillingCycle(false);
  }
  async function handleSubscribe() {
    setSubscribing(true);
    setErrorStatus('');
    try {
      if (
        paymentDetails?.current_payment_status === 'cancelled' ||
        paymentDetails?.current_payment_status === 'unpaid'
      ) {
        await axiosInstance.post('/api/payment/subscribe/', {
          next_plan_interval: !isMonthly ? 'year' : 'month',
          // promo_code: '',
        });
      } else if (
        paymentDetails?.next_payment_cancelled ||
        paymentDetails?.current_payment_status === 'pending'
      ) {
        await axiosInstance.post(`/api/payment/start-trial/`, {
          next_plan_interval: !isMonthly ? 'year' : 'month',
          // promo_code: '',
        });
      }
    } catch (err: any) {
      setErrorStatus(err.response?.data?.message || 'Failed to subscribe plan');
    }
    refetchPaymentDetails();
    setSubscribing(false);
  }
  async function handleConfirm() {
    setErrorStatus('');
    if (confirmType === 'cancel-plan') {
      setCancelingPlan(true);
      try {
        await axiosInstance.post('/api/payment/cancel-next-payment/', {
          cancel_next_payment: true,
        });
        refetchPaymentDetails();
      } catch (err: any) {
        setErrorStatus(err.response?.data?.message || 'Failed to cancel plan');
      }
      setCancelingPlan(false);
    }
  }
  const isPremium = paymentDetails?.plan_type !== 'unlimited';
  return (
    <div>
      <h1 className="fs-xxl mb-2 mb-md-4">Billing</h1>
      {errorStatus && <Alert variant="danger">{errorStatus}</Alert>}

      <Row className="border-bottom border-lg py-3 py-md-4">
        <Col className="mb-2">
          <p className="fs-lg fw-medium">Plan</p>
        </Col>
        <Col xs={12} md={5} className="mb-3">
          {paymentDetails?.payment_method_details &&
          paymentDetails.current_payment_status !== 'pending' ? (
            <>
              <p className="fs-xl mb-0 fw-semibold text-capitalize">
                {paymentDetails?.plan_type}{' '}
                {paymentDetails?.next_payment_cancelled && (
                  <span className="fs-sm text-warning">(Canceled)</span>
                )}
              </p>
              <p>
                {paymentDetails?.used_links_text}{' '}
                {isPremium && (
                  <span
                    className="text-primary"
                    role="button"
                    onClick={() => setShowUpgradePlanModal(true)}
                  >
                    {isPremium
                      ? 'Upgrade to Unlimited'
                      : 'Downgrade to Premium'}
                  </span>
                )}
              </p>
            </>
          ) : paymentDetails?.current_payment_status !== 'pending' ? (
            <p>You need to subscribe the payment to use app.</p>
          ) : (
            <p>
              You did not setup billing method. You will need to update your
              billing information
            </p>
          )}
        </Col>
        <Col className="d-flex align-items-center justify-content-start justify-content-md-end">
          {paymentDetails?.payment_method_details && (
            <ButtonGroup size="sm">
              {paymentDetails?.current_payment_status !== 'pending' &&
                !paymentDetails?.next_payment_cancelled &&
                paymentDetails?.current_payment_status !== 'cancelled' && (
                  <Button
                    variant="secondary"
                    className="rounded-0 text-nowrap"
                    disabled={cancelingPlan}
                    onClick={() => setConfirmType('cancel-plan')}
                  >
                    {cancelingPlan && <Spinner size="sm" className="me-2" />}
                    Cancel Plan
                  </Button>
                )}
              {(paymentDetails?.current_payment_status === 'unpaid' ||
                paymentDetails?.current_payment_status === 'pending') && (
                <Button
                  variant="primary"
                  className="rounded-0 text-nowrap"
                  disabled={subscribing}
                  onClick={() => handleSubscribe()}
                >
                  {subscribing && <Spinner size="sm" className="me-2" />}
                  Subscribe
                </Button>
              )}
              {isPremium &&
                !(
                  paymentDetails?.current_payment_status === 'unpaid' ||
                  paymentDetails?.current_payment_status === 'pending'
                ) && (
                  <Button
                    variant="outline-primary"
                    className="rounded-0 text-nowrap"
                    onClick={() => setShowUpgradePlanModal(true)}
                  >
                    Change Plan
                  </Button>
                )}
            </ButtonGroup>
          )}
        </Col>
      </Row>
      <Row className="border-bottom border-lg py-3 py-md-4">
        <Col className="mb-2">
          <p className="fs-lg fw-medium">Billing Cycle</p>
        </Col>
        <Col xs={12} md={5} className="mb-3">
          {isPremium ? (
            <p>
              ${paymentDetails?.profile_charges || 0} /{' '}
              {isMonthly ? 'month' : 'year'}, plus applicable taxes
            </p>
          ) : (
            <p>$1 per 100 links, plus applicable taxes</p>
          )}
          <p>
            {paymentDetails?.next_payment_date
              ? `Your next credit charge with be on ${moment(
                  paymentDetails.next_payment_date,
                ).format('DD MMM, yyyy')}`
              : 'Unsubscribed'}
          </p>
        </Col>
        <Col className="d-flex align-items-center justify-content-start justify-content-md-end">
          {isPremium &&
            paymentDetails?.payment_method_details &&
            paymentDetails?.current_payment_status !== 'pending' && (
              <Button
                size="sm"
                variant="outline-primary rounded-0 text-nowrap"
                disabled={switchingBillingCycle}
                onClick={() => setShowSwitchCycleConfirmModal(true)}
              >
                {switchingBillingCycle && (
                  <Spinner size="sm" className="me-2" />
                )}
                Switch to {isMonthly ? 'annual' : 'month'}
              </Button>
            )}
        </Col>
      </Row>
      <Row className="border-bottom border-lg py-3 py-md-4">
        <Col className="mb-2">
          <p className="fs-lg fw-medium">Billing Information</p>
        </Col>
        <Col xs={12} md={5} className="mb-3">
          {paymentDetails?.payment_method_details && (
            <>
              <p>
                Credit card number: **** **** ****{' '}
                {paymentDetails.payment_method_details.last4}
              </p>

              <p>
                Expiration Date:{' '}
                {`${paymentDetails.payment_method_details.exp_month < 10 ? '0' : ''}${paymentDetails.payment_method_details.exp_month} ${paymentDetails.payment_method_details.exp_year}`}
              </p>
              <p>
                Country:{' '}
                {paymentDetails.payment_method_details.address?.country}
              </p>
            </>
          )}
        </Col>
        <Col className="d-flex align-items-center justify-content-start justify-content-md-end">
          <Button
            size="sm"
            variant="outline-primary rounded-0"
            onClick={() => setShowBillingModal(true)}
          >
            Update
          </Button>
        </Col>
      </Row>
      <Row className="border-bottom border-lg py-3 py-md-4">
        <Col className="mb-2">
          <p className="fs-lg fw-medium">Billing receipts</p>
        </Col>
        <Col xs={12} md={5} className="mb-3">
          <Link
            to={appRoutes.billingReceipts}
            className="text-primary text-decoration-underline"
          >
            View
          </Link>
        </Col>
        <Col className="d-flex align-items-center justify-content-start justify-content-md-end"></Col>
      </Row>
      <Row className="border-bottom border-lg py-3 py-md-4">
        <Col className="mb-2">
          <p className="fs-lg fw-medium">Add Account(s)</p>
        </Col>
        <Col xs={12} md={5} className="mb-3">
          <p>{profiles.length} Profiles Linked</p>
        </Col>
        <Col className="d-flex align-items-center justify-content-start justify-content-md-end">
          <Link to={appRoutes.accounts}>
            <Button size="sm" variant="outline-primary rounded-0">
              Manage Account(s)
            </Button>
          </Link>
        </Col>
      </Row>
      <UpdateBillingModal
        interval={paymentDetails?.interval}
        open={showBillingModal}
        onClose={(refresh) => {
          setShowBillingModal(false);
          if (refresh) {
            refetchPaymentDetails();
          }
        }}
      />
      <UpgradePlanModal
        currentPlan={paymentDetails?.plan_type}
        open={showUpgradePlanModal}
        onClose={(refresh) => {
          setShowUpgradePlanModal(false);
          if (refresh) {
            refetchPaymentDetails();
          }
        }}
        onClickUpdatePayment={() => {
          setShowUpgradePlanModal(false);
          setShowBillingModal(true);
        }}
      />
      <SwitchCycleConfirmModal
        isMonthly={isMonthly}
        open={showSwitchCycleConfirmModal}
        onConfirm={(confirm) => {
          setShowSwitchCycleConfirmModal(false);
          if (confirm) {
            switchBillingCycle();
          }
        }}
      />
      {confirmType && (
        <ConfirmModal
          title={confirmContents[confirmType].title || ''}
          description={confirmContents[confirmType].description || ''}
          open={!!confirmType}
          onConfirm={(confirm) => {
            setConfirmType(null);
            if (confirm) handleConfirm();
          }}
        />
      )}
    </div>
  );
};

export default Billing;
