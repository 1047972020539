import React, { useEffect } from 'react';
import {
  Alert,
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosInstance from '@utils/axios-service';
import useAuth from '@hooks/useAuth';
import UserAvatar from '@components/UserAvatar';

type FormikData = {
  agencyName: string;
  file?: File;
  picture?: string;
};

const AgencyInfo: React.FC = () => {
  const navigate = useNavigate();
  const { authUser, paymentDetails, user, profiles, refetchUser } = useAuth();
  const name = user?.name || authUser?.displayName || '';
  const initialValues: FormikData = {
    agencyName: name,
    file: undefined,
    picture: user?.avatar,
  };
  useEffect(() => {
    if (paymentDetails?.payment_method_details && profiles.length === 0) {
      navigate(appRoutes.accounts);
    }
  }, [profiles, paymentDetails]);
  const validationSchema = yup.object({
    agencyName: yup.string().required('Agency name is required'),
  });
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async ({ agencyName, file }, { setSubmitting, setStatus }) => {
      setStatus('');
      setSubmitting(true);
      try {
        const accountFormData = new FormData();
        accountFormData.append('name', agencyName || '');
        if (file) {
          accountFormData.append('avatar', file, file.name.slice(-99));
        }
        await axiosInstance.put('/api/profile/account/update', accountFormData);
        await refetchUser();
        navigate(appRoutes.checkout + '?userType=agency');
      } catch (err: any) {
        setStatus(err.response?.data?.message || 'Failed to create account');
      }
      setSubmitting(false);
    },
  });
  return (
    <>
      <h2 className="fs-xl">Perfect! Now let’s setup your bot</h2>

      <div className="d-flex align-items-center">
        <div className="me-4">
          <UserAvatar
            size={85}
            editable
            imgUrl={formik.values.picture}
            onChangeFile={(file) => {
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  formik.setFieldValue('file', file);
                  formik.setFieldValue('picture', reader.result as string);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
        </div>
      </div>

      <Col xs={12} sm={12} md={12}>
        <FormGroup className="mb-2">
          <FormLabel>Agency Name</FormLabel>
          <FormControl
            placeholder={'Agency Name'}
            {...formik.getFieldProps('agencyName')}
            isInvalid={formik.touched.agencyName && !!formik.errors.agencyName}
          />
          {formik.touched.agencyName && !!formik.errors.agencyName && (
            <FormText className="text-danger">
              {formik.errors.agencyName}
            </FormText>
          )}
        </FormGroup>
      </Col>
      {formik.status && <Alert variant="warning">{formik.status}</Alert>}
      <div className="d-flex justify-content-between">
        <Button
          variant="secondary"
          disabled={formik.isSubmitting}
          onClick={() => navigate(appRoutes.selectUserType)}
        >
          Back
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
          Continue
        </Button>
      </div>
    </>
  );
};

export default AgencyInfo;
