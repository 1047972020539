import ReportAnalyticsCard from '@components/ReportAnalyticsCard';
import ScanHistoryCard from '@components/ScanHistoryCard';
import moment from 'moment';
import axiosInstance from '@utils/axios-service';
import { useEffect, useState } from 'react';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';
import { Range } from 'react-date-range';
import { ReportsResponse } from 'types/reports.type';
import useAuth from '@hooks/useAuth';
import UserJson from 'types/user.type';
import DashboardFilter from '@components/DashboardFilter';
import appConfig from '@constants/appConfig.app';

const Reports: React.FC = () => {
  const { profiles, paymentDetails } = useAuth();
  const [errorStatus, setErrorStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedProfile, setSelectedProfile] = useState<
    UserJson.Profile | undefined
  >(profiles[0]);
  const [selectedRange, setSelectedRange] = useState<Range>({
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: new Date(),
    key: 'range',
  });
  const [reports, setReports] = useState<ReportsResponse | null>(null);

  const fetchReportsData = async () => {
    setLoading(true);
    setErrorStatus('');
    try {
      const response = await axiosInstance.get(
        `/api/profile/reports?start_date=${moment(selectedRange.startDate).format('MM/DD/YY')}&end_date=${moment(selectedRange.endDate).format('MM/DD/YY')}${selectedProfile?.id ? `&profile_id=${selectedProfile?.id}` : ''}`,
      );
      setReports(response.data);
    } catch (err: any) {
      setErrorStatus(err.response?.data?.message || 'Failed to fetch data');
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchReportsData();
  }, [selectedRange, selectedProfile]);

  if (
    paymentDetails?.can_access_dashboard === false ||
    paymentDetails?.current_payment_status === 'pending'
  ) {
    return (
      <Alert>
        You don't have permission to access this page. Please update billing
        information in Billing page
      </Alert>
    );
  }
  return (
    <div>
      <h1 className="fs-xxl mb-3 mb-md-4">Analytics</h1>
      <DashboardFilter
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        profile={selectedProfile}
        setProfile={setSelectedProfile}
      />
      {errorStatus && <Alert variant="danger">{errorStatus}</Alert>}
      {loading ? (
        <div className="d-flex p-10 justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Row>
          <Col sm={12} md={6} lg={4} className="mb-4">
            {reports && (
              <ReportAnalyticsCard
                title="Total media removed"
                total={reports?.data?.media_removed?.total}
                percentage={reports?.data?.media_removed?.percentage}
                since={reports?.data?.media_removed?.since}
                up={reports?.data?.media_removed?.up}
                chart={reports?.data.media_removed?.chart}
              />
            )}
          </Col>
          <Col sm={12} md={6} lg={4} className="mb-4">
            {reports && (
              <ReportAnalyticsCard
                title={`${appConfig.APP_NAME} notices sent`}
                total={reports?.data?.dmca_notices_sent?.total}
                percentage={reports?.data?.dmca_notices_sent?.percentage}
                since={reports?.data?.dmca_notices_sent?.since}
                up={reports?.data?.dmca_notices_sent?.up}
                chart={reports?.data.dmca_notices_sent?.chart}
                maxValue={600}
              />
            )}
          </Col>
          <Col sm={12} md={6} lg={4} className="mb-4">
            {reports && (
              <ReportAnalyticsCard
                title="Value of removed leaks"
                total={reports?.data?.value_of_removed_media?.total}
                percentage={reports?.data?.value_of_removed_media?.percentage}
                since={reports?.data?.value_of_removed_media?.since}
                up={reports?.data?.value_of_removed_media?.up}
                chart={reports?.data.value_of_removed_media?.chart}
                maxValue={6000}
              />
            )}
          </Col>
          <Col sm={12} md={6} lg={4} className="mb-4">
            {reports && (
              <ReportAnalyticsCard
                title="Removed from Google"
                total={reports?.data?.removed_from_google?.total}
                percentage={reports?.data?.removed_from_google?.percentage}
                since={reports?.data?.removed_from_google?.since}
                up={reports?.data?.removed_from_google?.up}
                chart={reports?.data.removed_from_google?.chart}
                maxValue={600}
              />
            )}
          </Col>
          <Col sm={12} lg={8} className="mb-4">
            <ScanHistoryCard
              period={reports?.data?.scan_history?.period}
              chart={reports?.data?.scan_history?.chart}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Reports;
