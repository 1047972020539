import AuthProvider from '@providers/AuthProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppRoutes from 'pages';
import { ThemeProvider } from 'react-bootstrap';
import { appConfig } from './constants';

const stripePromise = loadStripe(appConfig.APP_STRIPE_PUBLIC_KEY);
function App() {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <AuthProvider>
        <Elements
          stripe={stripePromise}
          options={{
            appearance: {
              variables: {
                spacingUnit: '20px',
              },
              labels: 'above',
            },
          }}
        >
          <AppRoutes />
        </Elements>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
