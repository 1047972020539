import { AppLogoIcon } from '@assets/icons';
import appRoutes from '@constants/routes.app';
import useBreakpoint from '@hooks/useBreakpoint';
import { Link } from 'react-router-dom';

const AppLogo: React.FC = () => {
  const { isMobile } = useBreakpoint();
  return (
    <Link to={appRoutes.dashboard}>
      <AppLogoIcon width={isMobile ? 120 : 160} />
    </Link>
  );
};

export default AppLogo;
