import React from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Image,
  Spinner,
} from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import { useFormik } from 'formik';
import AuthJson from 'types/auth.type';
import * as yup from 'yup';
import authService from '@utils/auth-service';
import useAuth from '@hooks/useAuth';
import appConfig from '@constants/appConfig.app';
import PasswordField from '@components/PasswordField';

const initialValues: AuthJson.SignupInput = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const Signup: React.FC = () => {
  const { loading, authUser: currentUser } = useAuth();
  const navigate = useNavigate();
  const formik = useFormik<AuthJson.SignupInput>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus('');
      setSubmitting(true);
      try {
        await authService.register(values);
        navigate(appRoutes.selectUserType);
      } catch (error: any) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            setStatus('The email already in use');
            break;
          default:
            setStatus('Failed to create user.');
        }
      }
      setSubmitting(false);
    },
  });

  async function handleSigninWithGoogle() {
    try {
      await authService.signInWithGoogle();
      navigate(appRoutes.selectUserType);
    } catch {
      formik.setStatus('Invalid login attempt.');
    }
  }
  if (!loading && currentUser)
    return <Navigate to={appRoutes.selectUserType} />;
  return (
    <>
      <h2 className="fs-xl">
        Welcome to {appConfig.APP_NAME}! First, create your account
      </h2>
      {formik.status && <Alert variant="danger">{formik.status}</Alert>}
      <FormGroup className="">
        <FormLabel>Email</FormLabel>
        <FormControl
          placeholder="Email address"
          {...formik.getFieldProps('email')}
          isInvalid={formik.touched.email && !!formik.errors.email}
        />
        {formik.touched.email && !!formik.errors.email && (
          <FormText className="text-danger">{formik.errors.email}</FormText>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>Password</FormLabel>
        <PasswordField
          placeholder="Password"
          {...formik.getFieldProps('password')}
          isInvalid={formik.touched.password && !!formik.errors.password}
        />
        {formik.touched.password && !!formik.errors.password && (
          <FormText className="text-danger">{formik.errors.password}</FormText>
        )}
      </FormGroup>
      <Button
        className=""
        onClick={() => formik.handleSubmit()}
        disabled={formik.isSubmitting}
      >
        {formik.isSubmitting && <Spinner size="sm" className="me-2" />}
        Sign up
      </Button>
      <hr />
      <Button
        className="d-flex gap-3 justify-content-center align-items-center border-secondary"
        variant="outline-dark"
        onClick={handleSigninWithGoogle}
      >
        <Image src="/assets/images/google.png" width={22} height={22} />
        Sign up with Google
      </Button>
      <p className="text-muted fs-sm text-center">
        If you have account, please signin{' '}
        <Link to={appRoutes.signin} className="text-primary">
          here
        </Link>
      </p>
    </>
  );
};

export default Signup;
