import DashboardLayout from '@modules/layouts/dashboard-layout/DashboardLayout';
import DefaultLayout from '@modules/layouts/default-layout/DefaultLayout';
import { useRoutes } from 'react-router-dom';
import Dashboard from './dashboard';
import Home from './home';
import Reports from './reports';
import Billing from './billing';
import Accounts from './accounts';
import Faq from './faq';
import Signin from './signin';
import Signup from './signup';
import Checkout from './checkout';
import AuthLayout from '@modules/layouts/auth-layout/AuthLayout';
import UserInfo from './user-info';
import TermsPage from './terms';
import PrivacyPage from './privacy';
import ForgotPassword from './forgot-password';
import WhitelistedDomains from './whitelisted-domains';
import BillingHistory from './billing-history';
import SelectUserType from './select-user-type';
import AgencyInfo from './agency-info';

const AppRoutes: React.FC = () => {
  const routes = useRoutes([
    {
      path: '/*',
      element: <DefaultLayout />,
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: 'terms-of-services',
          element: <TermsPage />,
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPage />,
        },
      ],
    },
    {
      path: '/*',
      element: <AuthLayout />,
      children: [
        {
          path: 'signin',
          element: <Signin />,
        },
        {
          path: 'signup',
          element: <Signup />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'select-user-type',
          element: <SelectUserType />,
        },
        {
          path: 'agency-info',
          element: <AgencyInfo />,
        },
        {
          path: 'user-info',
          element: <UserInfo />,
        },
        {
          path: 'checkout',
          element: <Checkout />,
        },
      ],
    },
    {
      path: '/*',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'reports',
          element: <Reports />,
        },
        {
          path: 'billing',
          element: <Billing />,
        },
        {
          path: 'billing/receipts',
          element: <BillingHistory />,
        },
        {
          path: 'accounts',
          element: <Accounts />,
        },
        {
          path: 'whitelisted-domains',
          element: <WhitelistedDomains />,
        },
        {
          path: 'faq',
          element: <Faq />,
        },
      ],
    },
  ]);
  return routes;
};

export default AppRoutes;
