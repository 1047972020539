import { FaqIcon } from '@assets/icons';
import GraphUpDownArrowIcon from '@assets/icons/GraphUpArrow';
import { ChartData, ChartOptions } from 'chart.js';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartDataType } from 'types/reports.type';
import 'chartjs-adapter-date-fns';
import { format, parseISO, isValid, isDate } from 'date-fns';

type ReportAnalyticsCardProps = {
  title: string;
  total: React.ReactNode;
  percentage: string;
  up: boolean;
  since: string;
  chart: ChartDataType;
  maxValue?: number;
};

const ReportAnalyticsCard: React.FC<ReportAnalyticsCardProps> = ({
  title,
  total,
  percentage,
  up,
  since,
  chart,
  maxValue = 2000,
}) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (context) => {
            // const label = context.dataset?.label || '';
            // return `${label}  ${percentage}`;
            let label = context.dataset.label || '';
            if (context.parsed.y !== null) {
              label += ` $${context.parsed.y}`; // Combine label and y-axis value
            }
            return label;
          },
        },
        bodyFont: {
          size: 12,
        },
        titleFont: {
          size: 12,
        },
        padding: 10,
        backgroundColor: 'rgba(0,0,0,0.8)', // Customize background color
        borderColor: 'rgba(255,255,255,1)', // Customize border color
        borderWidth: 2, // Customize border width
        cornerRadius: 4, // Customize corner radius
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 5,
          minRotation: 0,
          maxRotation: 0,
          callback: function (value: any, index, ticks) {
            let date;
            if (typeof value === 'string' || value instanceof String) {
              date = parseISO(String(value));
            } else if (isDate(value)) {
              date = value;
            } else {
              return value; // Return the original value if it's not a valid date
            }

            if (!isValid(date)) {
              return value; // Return the original value if it's not a valid date
            }

            const today = new Date();
            const todayFormatted = format(today, 'yyyy-MM-dd');
            const dateFormatted = format(date, 'yyyy-MM-dd');

            if (dateFormatted === todayFormatted) {
              return 'Today';
            }

            return format(date, 'dd MM');
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        max: maxValue,

        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          includeBounds: true,
          callback: (v, index) => {
            const tickValue = parseInt(`${v}`);
            return tickValue >= 1000
              ? Math.round(tickValue / 100) / 10 + 'k'
              : tickValue;
          },
        },
      },
    },
  };

  const formattedData: ChartData<'line'> = useMemo(() => {
    return {
      datasets: [
        {
          data: Object.values(chart),
          label: '', // You can specify a label if needed
          borderColor: '#6aaaf9',
          borderWidth: 2,
          pointRadius: 0,
          pointHoverRadius: 5,
          fill: true,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // Return a default color or previous gradient instead of null
              return 'rgba(75,192,192,0.4)';
            }

            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom,
            );
            gradient.addColorStop(0, 'rgba(75,192,192,0.4)');
            gradient.addColorStop(1, 'rgba(75,192,192,0)');
            return gradient;
          },
        },
      ],
      labels: Object.keys(chart),
    };
  }, [chart]);
  return (
    <Card className="shadow-sm border-slight rounded-3 p-4">
      <div className="bg-white d-flex justify-content-between mb-3">
        <div>
          <p className="fs-xs">{title}</p>
          <div className="d-flex gap-1 align-items-center">
            <span className="fs-xl fw-bold me-2">{total}</span>
            <GraphUpDownArrowIcon down={up !== true} />
            <span
              className={clsx(
                'fs-sm',
                up === true ? 'text-success' : 'text-danger',
              )}
            >
              {/* {Math.abs(percentage)}% */}
              {percentage}
            </span>
            <span className="fs-sm">vs {since}</span>
          </div>
        </div>
        <FaqIcon width={13} height={13} color="#b3b6ba" />
      </div>
      <div>
        <Line options={options} data={formattedData} height={'200px'} />
      </div>
    </Card>
  );
};
export default ReportAnalyticsCard;
