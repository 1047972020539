import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import appRoutes from '@constants/routes.app';
import useAuth from '@hooks/useAuth';
import authService from '@utils/auth-service';

const SelectUserType: React.FC = () => {
  const navigate = useNavigate();
  const { paymentDetails, profiles } = useAuth();

  useEffect(() => {
    if (paymentDetails?.payment_method_details) {
      navigate(appRoutes.dashboard);
    } else if (profiles.length > 0) {
      navigate(appRoutes.checkout);
    }
  }, [profiles, paymentDetails]);

  return (
    <>
      <h2 className="fs-xl">Perfect! Now let’s setup your bot</h2>
      <div>
        <div className="d-flex flex-column gap-4">
          <Card
            className="user-category-card p-4"
            role="button"
            onClick={() => navigate(appRoutes.userInfo)}
          >
            Creator
          </Card>
          <Card
            className="user-category-card p-4"
            role="button"
            onClick={() => navigate(appRoutes.agencyInfo)}
          >
            Agency
          </Card>
        </div>
        <Button
          className="w-100 mt-4"
          variant="secondary"
          onClick={async () => {
            await authService.logout();
            navigate(appRoutes.home, { replace: true });
          }}
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default SelectUserType;
