import AppTypes from '@models/app.type';

const UpTriangleIcon: React.FC<AppTypes.Icon> = ({
  width = 7,
  height = 5,
  color = 'rgb(252, 153, 170)',
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        fillRule="evenodd"
        stroke="rgb(0, 0, 0)"
        strokeWidth="0px"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        fill={color}
        d="M2.734,0.505 C3.134,0.028 3.867,0.028 4.267,0.505 L6.375,3.017 C6.921,3.668 6.458,4.660 5.609,4.660 L1.392,4.660 C0.543,4.660 0.080,3.668 0.626,3.017 L2.734,0.505 Z"
      />
    </svg>
  );
};

export default UpTriangleIcon;
