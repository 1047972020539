import React from 'react';
import { Card, Stack } from 'react-bootstrap';

interface SiteCardProps {
  id: number;
  siteUrl: string;
  link: number;
}

const SiteCard: React.FC<SiteCardProps> = ({ id, siteUrl, link }) => {
  return (
    <Card body className="shadow-sm bg-white rounded-3 border-slight py-2">
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-center"
        gap={1}
      >
        <div className="d-flex gap-3 align-items-center">
          <h3 className="m-0">{id}.</h3>
          <h4 className="m-0 fs-4">{siteUrl}</h4>
        </div>
        <div>
          <h4 className="m-0 fs-6 text-middle-muted">{link} links</h4>
        </div>
      </Stack>
    </Card>
  );
};

export default SiteCard;
