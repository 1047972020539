const BillingIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="21px">
      <defs>
        <filter id="Filter_0">
          <feFlood
            floodColor="rgb(106, 170, 249)"
            floodOpacity="1"
            result="floodOut"
          />
          <feComposite
            operator="atop"
            in="floodOut"
            in2="SourceGraphic"
            result="compOut"
          />
          <feBlend mode="normal" in="compOut" in2="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#Filter_0)">
        <path
          fillRule="evenodd"
          fill="rgb(133, 163, 187)"
          d="M15.581,20.749 L6.707,20.749 C3.166,20.749 0.286,17.827 0.286,14.236 L0.286,6.755 C0.286,3.163 3.166,0.242 6.707,0.242 L15.581,0.242 C19.122,0.242 22.002,3.163 22.002,6.755 L22.002,14.236 C22.002,17.827 19.122,20.749 15.581,20.749 ZM19.933,12.118 L19.933,8.598 L16.812,8.598 C15.859,8.598 15.083,9.388 15.083,10.358 C15.083,11.271 15.784,12.042 16.677,12.112 L16.822,12.118 L19.933,12.118 ZM13.013,10.358 C13.013,8.314 14.590,6.626 16.603,6.515 L16.805,6.508 L19.927,6.508 C19.800,4.183 17.899,2.331 15.581,2.331 L6.707,2.331 C4.308,2.331 2.355,4.316 2.355,6.755 L2.355,14.236 C2.355,16.674 4.308,18.660 6.707,18.660 L15.581,18.660 C17.981,18.660 19.933,16.674 19.933,14.236 L19.933,14.208 L16.811,14.208 C14.718,14.205 13.014,12.478 13.013,10.358 ZM11.660,7.051 L11.537,7.060 L5.976,7.060 C5.406,7.060 4.941,6.592 4.941,6.017 C4.941,5.496 5.326,5.051 5.836,4.981 L5.959,4.972 L11.519,4.972 C12.089,4.972 12.554,5.440 12.554,6.017 C12.554,6.536 12.170,6.981 11.660,7.051 ZM15.927,10.294 C15.927,9.774 16.311,9.329 16.822,9.258 L17.282,9.249 C17.853,9.249 18.317,9.718 18.317,10.294 C18.317,10.814 17.933,11.258 17.422,11.329 L16.962,11.339 C16.392,11.339 15.927,10.869 15.927,10.294 Z"
        />
      </g>
    </svg>
  );
};

export default BillingIcon;
