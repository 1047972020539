import UploadIcon from '@assets/icons/UploadIcon';
import React, { useCallback } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'react-bootstrap';
import { Accept, useDropzone } from 'react-dropzone';

interface FileDropzoneProps {
  description?: string;

  onDrop: (acceptedFiles: File[]) => void;
  accept?: Accept;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onDrop,
  description = 'Drop your Excel file here for mass uploading',
  accept = {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'text/csv': ['.csv'],
  },
}) => {
  const onDropCallback = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback,
    accept,
  });

  return (
    <div
      {...getRootProps()}
      className="dropzone border border-primary rounded-3 p-3 text-center border-dashed d-flex flex-column align-items-center gap-2"
    >
      <input {...getInputProps()} />
      <UploadIcon />
      {description}
      <Dropdown>
        <DropdownToggle size="sm" variant="secondary">
          Upload
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Upload from local</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default FileDropzone;
