import appConfig from '@constants/appConfig.app';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const TermsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="terms_section">
      {/* <Container fluid={'xxl'}> */}
      <Row>
        <Col lg={12} className="col_heading py-5">
          <h1 className="py-3 text-center">Terms and Conditions</h1>
        </Col>
      </Row>
      {/* </Container> */}
      <Container>
        <Row>
          <Col>
            <h3 className="py-5">
              Welcome to {appConfig.APP_NAME}! These terms and conditions
              outline the rules and regulations for the use of our{' '}
              {appConfig.APP_NAME} service, provided by
              {appConfig.APP_NAME}. By accessing this service, we assume you
              accept these terms and conditions. Do not continue to use{' '}
              {appConfig.APP_NAME} if you do not agree to all the terms and
              conditions stated on this page.
            </h3>
            <ol>
              <li className="fw-semibold">
                License to Use {appConfig.APP_NAME}
              </li>
              <ol>
                <li>
                  We grant you a non-exclusive, non-transferable license to use
                  our {appConfig.APP_NAME} for the purpose of monitoring and
                  addressing copyright infringement instances related to your
                  digital content.
                </li>
                <li>
                  This license is subject to these terms and conditions and does
                  not permit any resale or commercial use of the service without
                  our prior written consent.
                </li>
              </ol>
              <li className="fw-semibold">Copyright Infringement</li>
              <ol>
                <li>
                  You acknowledge and agree that {appConfig.APP_NAME} may
                  automatically initiate {appConfig.APP_NAME} takedown requests
                  and explore legal alternatives to address copyright
                  infringement instances identified by the service.
                </li>
                <li>
                  By using {appConfig.APP_NAME}, you consent to the delisting or
                  removal of copyright infringement content from search engines
                  and other online platforms.
                </li>
              </ol>
              <li className="fw-semibold">User Responsibilities</li>
              <ol>
                <li>
                  You are responsible for ensuring that you have the necessary
                  rights and permissions to use {appConfig.APP_NAME} and to take
                  action against copyright infringement instances related to
                  your digital content.
                </li>
                <li>
                  You agree to provide accurate and up-to-date information to
                  facilitate the effective operation of {appConfig.APP_NAME}.
                </li>
              </ol>
              <li className="fw-semibold">Limitation of Liability</li>
              <ol>
                <li>
                  We strive to provide a reliable and effective service;
                  however, we cannot guarantee 100% accuracy or success in
                  identifying and addressing copyright infringement instances.
                </li>
                <li>
                  We shall not be liable for any direct, indirect, incidental,
                  special, or consequential damages resulting from the use or
                  inability to use {appConfig.APP_NAME}.
                </li>
              </ol>
              <li className="fw-semibold"> Privacy Policy</li>
              <ol>
                <li>
                  Your privacy is important to us. Please refer to our Privacy
                  Policy for information on how we collect, use, and disclose
                  personal information in connection with {appConfig.APP_NAME}.
                </li>
              </ol>
              <li className="fw-semibold">Termination</li>
              <ol>
                <li>
                  We reserve the right to terminate or suspend your access to
                  {appConfig.APP_NAME} at any time, with or without cause and
                  with or without notice.
                </li>
                <li>
                  Upon termination, you must cease all use of{' '}
                  {appConfig.APP_NAME} and delete any copies of the service in
                  your possession.
                </li>
              </ol>
              <li className="fw-semibold">Amendments</li>
              <ol>
                <li>
                  We may revise these terms and conditions from time to time
                  without prior notice. Revised terms will apply to the use of
                  {appConfig.APP_NAME} from the date of publication.
                </li>
              </ol>
              <li className="fw-semibold">Governing Law</li>
              <ol>
                <li>
                  These terms and conditions are governed by and construed in
                  accordance with the laws of California, and you irrevocably
                  submit to the exclusive jurisdiction of the courts in that
                  jurisdiction.
                </li>
              </ol>
            </ol>
            <h5 className="py-5">
              By using {appConfig.APP_NAME}, you acknowledge that you have read,
              understood, and agree to be bound by these terms and conditions.
              If you do not agree to these terms and conditions, please refrain
              from using
              {appConfig.APP_NAME}. If you have any questions or concerns,
              please contact us at{' '}
              <span className="text-primary">
                <a
                  href="mailto:support@noleaks.com"
                  rel="noreferrer"
                  target="_blank"
                  className="text-decoration-none"
                >
                  support@noleaks.com
                </a>
              </span>
              .
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsPage;
