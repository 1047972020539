import { Navigate, Outlet } from 'react-router-dom';
import DashboardAsideBar from './DashboardAsideBar';
import DashboardHeader from './DashboardHeader';
import { useEffect, useState } from 'react';
import useBreakpoint from '@hooks/useBreakpoint';
import clsx from 'clsx';
import DashboardStickyMenu from './DashboardStickyMenu';
import useAuth from '@hooks/useAuth';
import appRoutes from '@constants/routes.app';
import { Alert } from 'react-bootstrap';
import moment from 'moment';

const DashboardLayout: React.FC = () => {
  const [showTrialAlert, setShowTrialAlert] = useState(true);
  const { loading, authUser: currentUser, paymentDetails } = useAuth();
  const [expanded, setExpanded] = useState(true);
  const { isMobile } = useBreakpoint();
  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [isMobile]);
  const leftDays = paymentDetails
    ? moment(paymentDetails.next_payment_date).diff(moment(), 'days')
    : 0;

  const hasAlert =
    paymentDetails?.current_payment_status === 'trial' &&
    showTrialAlert &&
    leftDays > 0;
  if (!loading && !currentUser) return <Navigate to={appRoutes.home} />;
  return (
    <div
      className={clsx('dashboard bg-light min-vh-100', hasAlert && 'has-alert')}
    >
      {hasAlert && (
        <Alert
          className="mb-0 rounded-0"
          dismissible
          onClose={() => setShowTrialAlert(false)}
          show={showTrialAlert}
        >
          {leftDays} days left in your free trial, or 20k links. Upgrade your
          plan for unlimited DMCA Reports!
        </Alert>
      )}
      <div className="d-flex">
        <DashboardAsideBar expanded={expanded} setExpanded={setExpanded} />

        <div className={clsx('main', expanded && 'expanded')}>
          <DashboardHeader expanded={expanded} setExpanded={setExpanded} />
          <main className="flex-grow-1 p-3 p-md-5">
            <Outlet />
          </main>
        </div>
        {isMobile && <DashboardStickyMenu />}
      </div>
    </div>
  );
};

export default DashboardLayout;
